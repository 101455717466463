import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sbcnv-lista-card',
    templateUrl: './lista-card.component.html',
    styleUrls: ['./lista-card.component.scss'],
})
export class ListaCardComponent {
    @Input() public titulo = '';
    @Input() public quantidadeTexto = '';
    @Input() public quantidadeValor = '';
    @Input() public inputPlaceholder = '';
    @Output() eventoKeypress = new EventEmitter();

    constructor() {}

    chamarEventoKeypress(evento: KeyboardEvent): void {
        this.eventoKeypress.emit(evento);
    }
}
