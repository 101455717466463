import { MatDialogRef ,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-next-step-confirm',
  templateUrl: './next-step-confirm.component.html',
  styleUrls: ['./next-step-confirm.component.scss']
})
export class NextStepConfirmComponent  {

    @Input() titulo = '';
    @Input() conteudo ='';
    @Input() icone = 'info';
    @Input() btnConfirmar = 'SIM';
    @Input() btnCancelar = 'NÃO';

  constructor(
      public dialogRef: MatDialogRef<NextStepConfirmComponent>,
      @Inject(MAT_DIALOG_DATA)
      public data : {
          titulo: string;
          conteudo: string;
          icone: string;
          btnConfirmar: string;
          btnCancelar: string;
      }
  ) {

    if (data.icone !== undefined) {
            this.icone = data.icone;
        } else {
            this.icone = 'info';
        }

        if (data.btnCancelar !== undefined) {
            this.btnCancelar = data.btnCancelar;
        }
        if (data.btnConfirmar !== undefined) {
            this.btnConfirmar = data.btnConfirmar;
        }
        if (data.btnCancelar !== undefined) {
            this.btnCancelar = data.btnCancelar;
        }
  }

    public cancelar(): void {
        this.dialogRef.close(false);
    }

    public confirmar(): void {
        this.dialogRef.close(true);
    }

}
