import { Component, Input } from '@angular/core';

import { DialogService } from '@shared/dialog/dialog.service';

@Component({
    selector: 'sbcnv-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
    @Input() titulo = '';
    @Input() conteudo = '';
    icone = 'help_outline';

    constructor(private _dialogService: DialogService) {}

    public exibir(): void {
        this._dialogService.exibir({
            titulo: this.titulo,
            conteudo: this.conteudo,
            icone: this.icone,
        });
    }
}
