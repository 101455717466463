<mat-card>
    <div style="margin: 0 16px;">
        <h3>
            Dados do projeto
        </h3>
        Veja abaixo algumas informações do projeto
    </div>
    <div class="divFormulario" style="margin: 16px 16px;">
        <div id="div-informacoes-projeto" class="flex-container-30">
            <div class="flex-item-30">
                <p *ngIf="projeto.ind_aditivo_cnv ==='0'"><b><u>Projeto</u>:</b> {{ projeto.cdn_projeto }}</p>
                <p *ngIf="projeto.ind_aditivo_cnv !=='0'"><b><u>Projeto</u>:</b> {{ projeto.cdn_projeto }} - {{
                    projeto.ind_aditivo_cnv }}</p>
                <p><b><u>Convênio</u>:</b> {{ convenio.cod_convenio }}</p>
            </div>
            <div class="flex-item-30">
                <div style="border-left: 1px solid #B0B0B0; padding-left: 3em; height: 96px;">
                    <p><b>Valor do convênio:</b> R$ {{ convenio.val_tot_convenio }}</p>
                    <p><b>Valor à liberar:</b> R$ {{ convenio.val_liberar }}</p>
                    <p><b>Valor pendente de prestação:</b> R$ {{ convenio. val_pendente}}</p>
                </div>
            </div>
            <div class="flex-item-30">
                <div style="border-left: 1px solid #B0B0B0; padding-left: 3em; height: 96px;">
                    <p><b>Data início:</b> {{ convenio.dat_val_ini }}</p>
                    <p><b>Data término:</b> {{ convenio.dat_val_fim }}</p>
                    <p><b>Data limite PC:</b> {{ dataLimitePC }}</p>
                </div>
            </div>
        </div>
    </div>
</mat-card>