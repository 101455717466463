import { Component, OnInit } from '@angular/core';
import { UtilHelper } from '@app/helpers/util-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacaoService } from '@app/service';
import { DocumentoProjetoService } from '@data/service/projetos/documento-projeto.service';
import { DialogService } from '@shared/dialog/dialog.service';
import { ResponsavelService } from '@data/service/projetos/responsavel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Projeto } from '@data/models/projetos/projeto';
import { Responsavel } from '@data/models/projetos/responsavel';
import { IOption } from '@shared/formulario/select/select.component';
import { DocumentoProjeto } from '@data/models/projetos/documento-projeto';
import { ArrayHelper } from '@app/helpers/array-helper';
import { ResponsavelLegalParceiro } from '@data/models/projetos/responsavel-legal-parceiro';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

@Component({
    selector: 'sbcnv-resumo-dados-gerais',
    templateUrl: './resumo-dados-gerais.component.html',
    styleUrls: ['./resumo-dados-gerais.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
            ),
        ]),
    ],
})
export class ResumoDadosGeraisComponent implements OnInit {
    public projetoId = '0';
    public aditivoId = '0';
    public idAditivoDescricao = 0;
    public projeto!: Projeto;

    dataSourceDocumentosProjeto: DocumentoProjeto[] = [];
    responsaveisLegaisParceiro: Array<IOption> = [];

    exibirColunasDocumentoProjeto: string[] = [
        'desc_anexo',
        'nome_arq_proj',
        'acoes',
    ];

    public voltarUrl = '';

    public projetoNumeroPessoaJuridica = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _autenticacaoService: AutenticacaoService,
        private _documentoProjetoService: DocumentoProjetoService,
        private _dialogService: DialogService,
        private _responsavelService: ResponsavelService,
        private _snackBar: MatSnackBar,
    ) {}

    async ngOnInit() {
        this.idAditivoDescricao = 0;

        // Pega do resolve os dados do projeto
        if (this.route.snapshot.data.projeto) {
            this.projeto = this.route.snapshot.data.projeto;

            this.projetoId = this.projeto.cdn_projeto;

            this.voltarUrl = UtilHelper.replaceCuringaTipoUsuarioUrlVoltar(
                true,
                this.route.snapshot.data.voltarUrl,
                this._autenticacaoService.usuario,
            );

            this.projetoNumeroPessoaJuridica = this.projeto.num_pessoa_jurid;

            this.carregarDocumentosAnexados();
            this.carregarResponsaveisLegaisParceiro();
        }

        // Pegar parametros da rota
        this.route.params.subscribe((parametros) => {
            if (parametros.id) {
                this.projetoId = parametros.id;
            }
            if (parametros.seqAditivo) {
                this.aditivoId = parametros.seqAditivo;
            }
        });

        if (this.aditivoId !== '0') {
            this.idAditivoDescricao = Number(this.aditivoId) - 1;
        } else {
            this.idAditivoDescricao = Number(this.projeto.ind_aditivo_cnv);
        }
    }

    pegarAcompanhanteParceiro(): string {
        let retorno = '';

        this.projeto.responsaveis = ArrayHelper.normalizar<Responsavel>(
            this.projeto.responsaveis,
        );

        retorno = this.projeto.responsaveis
            .find(
                (responsavel) =>
                    responsavel.cpf === this.projeto.cpf_responsavel,
            )
            ?.nome as string;

        if(!retorno){
            retorno = 'ACOMPANHANTE PARCEIRO NÃO INFORMADO';
        }

        return retorno;
    }

    pegarResponsavelLegal(): string {
        let retorno = '';

        retorno = this.responsaveisLegaisParceiro
        .find((responsavelLegal) =>
            responsavelLegal.valor === this.projeto.cpf_resp_legal,
        )?.texto as string;

        if(!retorno){
            retorno = 'RESPONSAVEL LEGAL PARCEIRO NÃO INFORMADO';
        }

        return retorno;
    }

    pegarAcompanhanteSebrae(): string {
        let retorno = '';

        retorno = this.projeto.nom_func_responsavel || this.projeto.cdn_func_responsavel[1] ?
                this.projeto.nom_func_responsavel : 'ACOMPANHANTE SEBRAE NÃO INFORMADO';

        return retorno.toUpperCase();
    }

    voltar(): void {
        this.router.navigate([this.voltarUrl], {
            skipLocationChange: true,
        });
    }

    carregarDocumentosAnexados(): void {
        this._documentoProjetoService
            .carregarDocumentosProjeto(this.projetoId, '1,7')
            .subscribe(
                (resposta) => {
                    this.dataSourceDocumentosProjeto = [];
                    if (resposta.documentos) {
                        if (Array.isArray(resposta.documentos)) {
                            this.dataSourceDocumentosProjeto =
                                resposta.documentos;
                        } else {
                            const documento: DocumentoProjeto[] = [];
                            documento.push(resposta.documentos);
                            this.dataSourceDocumentosProjeto = documento;
                        }
                    }
                },
                (erro) => {
                    this._dialogService.exibir({
                        titulo: 'Atenção',
                        conteudo: erro.error,
                        jsonChamada: JSON.stringify(erro.body),
                        isErroServidor: true,
                    });
                },
            );
    }

    abrirArquivoURL(idAnexo: string): void {
        let url = '';
        this.dataSourceDocumentosProjeto.forEach((element) => {
            if (element.seq_anexo === idAnexo) {
                url = (element as DocumentoProjeto).loc_arq_anexo_proj;
            }
        });
        if (url.length > 0) {
            window.open(url, '_blank', 'noopener');
        }
    }

    carregarResponsaveisLegaisParceiro(): void {
        if (!this._autenticacaoService.usuario) {
            throw new Error(
                'Não foi possível pegar as informações do usuário logado no sistema.',
            );
        }
        let acessoCodigo = '';
        if (this._autenticacaoService.usuario.tipo === 'Parceiro') {
            acessoCodigo = this._autenticacaoService.usuario.pessoa_jurid;
        } else if (this._autenticacaoService.usuario.tipo === 'sebrae') {
            acessoCodigo = this.projetoNumeroPessoaJuridica;
        }
        this._responsavelService
            .getResponsaveisLegaisParceiro(acessoCodigo)
            .subscribe(
                (resposta) => {
                    const retorno = [...Object.values(resposta as any[])];
                    if (Array.isArray(retorno)) {
                        const arrayResp =
                            ArrayHelper.normalizar<ResponsavelLegalParceiro>(
                                retorno[0].assinatura,
                            );
                        arrayResp.forEach((respLegalParc: any) => {
                            if (respLegalParc) {
                                this.responsaveisLegaisParceiro.push({
                                    valor: respLegalParc.cpf_contato,
                                    texto: respLegalParc.nom_contato,
                                });
                            }
                        });
                    }
                    if (retorno.length === 0) {
                        this._snackBar.open(
                            'Não foi possível carregar os itens do campo responsável.',
                            undefined,
                            {
                                duration: 5000,
                                panelClass: ['snackbar-warn'],
                                horizontalPosition: 'right',
                                verticalPosition: 'top',
                            },
                        );
                    }
                },
                (erro) => {
                    this._dialogService.exibir({
                        titulo: 'Atenção',
                        conteudo: erro.error,
                        jsonChamada: JSON.stringify(erro.body),
                        isErroServidor: true,
                    });
                },
            );
    }
}
