<mat-card>
    <div class="mt-20 ml-20 mr-20 mb-40">
        <h3
            *ngIf="idAditivoDescricao === 0 || idAditivoDescricao === undefined"
            style="opacity: 60%; color: black; margin-top: 1em"
        >
            Conferir todos os repasses de valores - Projeto {{ projetoId }}
        </h3>
        <h3
            *ngIf="idAditivoDescricao !== 0 && idAditivoDescricao !== undefined"
            style="opacity: 60%; color: black; margin-top: 1em"
        >
            Conferir todos os repasses de valores - Projeto {{ projetoId }} -
            {{ idAditivoDescricao }}
        </h3>
        <br />
        <!-- inicio tablela -->
        <div class="m-10" *ngIf="!repasses || repasses.length === 0">
            Não há repasse definido
        </div>
        <div>
            <fieldset>
                <legend>Saldo Recurso</legend>
                <div class="flex-container">
                    <div class="flex-item">
                        <mat-form-field appearance="outline">
                            <mat-label>SEBRAE</mat-label>
                            <input
                                type="text"
                                disabled
                                matInput
                                [(ngModel)]="saldoRecursoSebrae"
                                mask="separator.2"
                                thousandSeparator="."
                            />
                            <span
                                *ngIf="valorPositivoSebrae"
                                style="
                                    font-size: 14px;
                                    float: right;
                                    color: rgba(0, 0, 0, 0.38);
                                "
                                matPrefix
                                >R$&nbsp;
                            </span>
                            <span
                                *ngIf="!valorPositivoSebrae"
                                style="
                                    font-size: 14px;
                                    float: right;
                                    color: rgba(0, 0, 0, 0.38);
                                "
                                matPrefix
                            >
                                - R$&nbsp;
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item">
                        <mat-form-field appearance="outline">
                            <mat-label>PARCEIRO</mat-label>
                            <input
                                type="text"
                                disabled
                                matInput
                                [(ngModel)]="saldoRecursoParceiro"
                                mask="separator.2"
                                thousandSeparator="."
                            />
                            <span
                                *ngIf="valorPositivoParceiro"
                                style="
                                    font-size: 14px;
                                    float: right;
                                    color: rgba(0, 0, 0, 0.38);
                                "
                                matPrefix
                                >R$&nbsp;
                            </span>
                            <span
                                *ngIf="!valorPositivoParceiro"
                                style="
                                    font-size: 14px;
                                    float: right;
                                    color: rgba(0, 0, 0, 0.38);
                                "
                                matPrefix
                            >
                                - R$&nbsp;
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-container" *ngIf="!valorPositivoSebrae">
                    <div class="flex-item">
                        <span style="color: rgba(238, 16, 16, 0.952)">
                            O valor total dos repasses cadastrados não deve
                            superar R$ {{ totalDespesasSebrae }}
                        </span>
                    </div>
                </div>
                <div class="flex-container" *ngIf="!valorPositivoParceiro">
                    <div class="flex-item">
                        <span style="color: rgba(238, 16, 16, 0.952)">
                            O valor total dos repasses cadastrados não deve
                            superar R$ {{ totalDespesasParceiro }}
                        </span>
                    </div>
                </div>
            </fieldset>
            <br />
            <table
                *ngIf="repasses.length > 0"
                mat-table
                [dataSource]="dataSourceRepasse"
                class="mat-elevation-z8"
            >
                <!-- Coluna Id -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        {{ i + 1 }}
                    </td>
                </ng-container>

                <!-- Coluna Parcela -->
                <ng-container matColumnDef="parcela">
                    <th mat-header-cell *matHeaderCellDef>Parcela</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.parcela }}&ordf;
                    </td>
                </ng-container>

                <!-- Coluna Tipo de repasse -->
                <ng-container matColumnDef="tipoRepasse">
                    <th mat-header-cell *matHeaderCellDef>Tipo de Repasse</th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            pegarDescricaoTipoRepassePorId(element.tipoRepasse)
                        }}
                    </td>
                </ng-container>

                <!-- Valor -->
                <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef>Valor</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.valor }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="exibirColunasRepasse"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: exibirColunasRepasse"
                ></tr>
            </table>
        </div>
        <!-- fim tabela -->
    </div>
</mat-card>
