import { IOption } from './../../shared/formulario/select/select.component';
import { Login } from './../../data/models/autenticacao/login';

export class UtilHelper {
    public static replaceCuringaTipoUsuarioUrlVoltar(
        voltar: boolean,
        voltarUrl: string,
        usuario: Login | null
    ): string {
        let url = voltarUrl;
        if (usuario && voltar) {
            url = String(voltarUrl).replace(
                '#tipousuario#',
                usuario.tipo.toLowerCase()
            );
        }
        return url;
    }

    public static formDataToString(formData: FormData): string {
        let formDataAsString = '';
        formData.forEach((value, key) => {
            formDataAsString += `${key}: ${ JSON.stringify(value)}\n`;
        });
        return formDataAsString;
    }

    public static compare(a: IOption, b: IOption): number {
        const bandA = a.texto.toUpperCase();
        const bandB = b.texto.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }
}
