<div class="alinhar-icone-texto">
    <span *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <mat-icon
            *ngIf="last && breadcrumb.voltar"
            aria-hidden="false"
            [routerLink]="breadcrumb.voltarUrl"
            [skipLocationChange]="breadcrumb.voltar"
            >arrow_back</mat-icon
        >

        <h3 *ngIf="breadcrumb.label && last">
            &nbsp;&nbsp;{{ breadcrumb.label }}
        </h3>
    </span>
</div>
