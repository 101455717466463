<mat-horizontal-stepper
    [linear]="ehLinear"
    [selectedIndex]="passoAtual"
>
    <mat-step
        *ngFor="let step of Steps"
        [label]="step.texto"
        [state]="step.estado"
        [completed]="step.concluido"
    ></mat-step>
</mat-horizontal-stepper>
