import { NgModule } from '@angular/core';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
    faBars,
    faBell,
    faBriefcase,
    faChalkboardTeacher,
    faChartLine,
    faCommentsDollar,
    faHandshake,
    faQuestionCircle,
    faShoppingCart,
    faTh,
    faUserCircle,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [],
    imports: [],
})
export class GeneralFontAwesomeModule {
    constructor(faIconLibrary: FaIconLibrary) {
        faIconLibrary.addIcons(
            faBars,
            faBell,
            faBriefcase,
            faChalkboardTeacher,
            faChartLine,
            faCommentsDollar,
            faHandshake,
            faQuestionCircle,
            faShoppingCart,
            faTh,
            faUserCircle,
            faUsers
        );
    }
}
