import { ItemMenu } from './item-menu';

export class Menu {
    constructor() {
        this.nomeAplicacao = '';
        this.itens = new Array<ItemMenu>();
    }

    public nomeAplicacao: string;
    public itens: Array<ItemMenu>;
}
