import { Component, Input } from '@angular/core';

@Component({
    selector: 'sbcnv-titulo-card-simples',
    templateUrl: './titulo-card-simples.component.html',
    styleUrls: ['./titulo-card-simples.component.scss'],
})
export class TituloCardSimplesComponent {
    @Input() public color = '';
    @Input() public titulo = '';

    constructor() {}
}
