import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    FormGroup,
    AbstractControl,
    ControlContainer,
    FormGroupDirective,
} from '@angular/forms';
import { Helpers } from './../helpers';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'sbcnv-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class SelectComponent {
    @Input() public class = '';
    @Input() public label = '';
    @Input() public placeholder = '';
    // exemplo public testeSelect = [  { valor: 'valor 1', texto: 'texto 1' },   { valor: 'valor 2', texto: 'texto 2' },   ];
    @Input() public opcoes: Array<IOption> = [];
    @Input() public controlName = '';
    @Input() public color: ThemePalette = 'accent';
    @Input() public hintTitulo = '';
    @Input() public hintConteudo = '';
    @Input() public formulario!: FormGroup;
    @Output() eventoChange = new EventEmitter();

    constructor() { }

    get campo(): AbstractControl {
        return Helpers.getFormControl(this.controlName, this.formulario);
    }

    chamarEventoChange(evento: FocusEvent): void {
        this.eventoChange.emit(evento);
    }
}
export interface IOption {
    valor: string;
    texto: string;
}

export interface IDespesaTipo {
    valor: string;
    texto: string;
    tipo: string;
    sequencia: string;
    idPagador: string;
}
