import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    ControlContainer,
    FormGroupDirective,
    FormGroup,
    AbstractControl,
} from '@angular/forms';
import { Helpers } from '../helpers';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'sbcnv-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class InputComponent {
    @Input() public class = '';
    @Input() public placeholder = '';
    @Input() public label = '';
    @Input() public type = 'text';
    @Input() public evento = '';
    @Input() public min = '';
    @Input() public max = '';
    @Input() public suffix = '';
    @Input() public prefix = '';
    @Input() public ngxMask = '';
    @Input() public ngxValidation = false;
    @Input() public ngxThousandSeparator = '';
    @Input() public controlName = '';
    @Input() public color: ThemePalette = 'accent';
    @Input() public hintTitulo = '';
    @Input() public hintConteudo = '';
    @Input() public formulario!: FormGroup;
    @Output() eventoBlur = new EventEmitter();
    @Output() eventoInput = new EventEmitter();

    constructor() {}

    get campo(): AbstractControl {
        return Helpers.getFormControl(this.controlName, this.formulario);
    }

    chamarEventoBlur(evento: FocusEvent): void {
        this.eventoBlur.emit(evento);
    }

    chamarEventoInput(evento: Event): void {
        this.eventoInput.emit(evento);
    }
}
