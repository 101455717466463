import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from './material.module';
import { FormataConvenioPipe } from './pipes/formata-convenio.pipe';
import { BarraAcaoComponent } from './barra-acao/barra-acao.component';
import { BarraConvenioComponent } from './barra-convenio/barra-convenio.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NextStepConfirmComponent } from './next-step-confirm/next-step-confirm.component';
import { DatepickerComponent } from './formulario/datepicker/datepicker.component';
import { DialogComponent } from './dialog/dialog.component';
import { GeneralFontAwesomeModule } from './general-font-awesome.module';
import { HintComponent } from './hint/hint.component';
import { InfoUsuarioComponent } from './info-usuario/info-usuario.component';
import { InputComponent } from './formulario/input/input.component';
import { ListaCardComponent } from './lista-card/lista-card.component';
import { ProjetoRowCardComponent } from './lista-card/projeto-row-card/projeto-row-card.component';
import { SelectComponent } from './formulario/select/select.component';
import { StepperComponent } from './stepper/stepper.component';
import { TextareaComponent } from './formulario/textarea/textarea.component';
import { TituloCardComponent } from './titulo-card/titulo-card.component';
import { TituloCardSimplesComponent } from './titulo-card/titulo-card-simples/titulo-card-simples.component';
import { ValidacaoFormularioComponent } from './formulario/validacao-formulario/validacao-formulario.component';
import { SafePipe } from '@shared/pipes/safe.pipe';
import {ResumoDadosGeraisComponent} from "@shared/resumo-dados-gerais/resumo-dados-gerais.component";
import {ResumoAcoesDespesasComponent} from "@shared/resumo-acoes-despesas/resumo-acoes-despesas.component";
import {ResumoRepassesComponent} from "@shared/resumo-repasses/resumo-repasses.component";
import {ResumoParticipesComponent} from "@shared/resumo-participes/resumo-participes.component";

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
        validation: false,
    };
};

@NgModule({
    declarations: [
        FormataConvenioPipe,
        SafePipe,
        BarraAcaoComponent,
        BarraConvenioComponent,
        BreadcrumbsComponent,
        ConfirmComponent,
        NextStepConfirmComponent,
        DatepickerComponent,
        DialogComponent,
        HintComponent,
        InfoUsuarioComponent,
        InputComponent,
        ListaCardComponent,
        ProjetoRowCardComponent,
        SelectComponent,
        StepperComponent,
        TextareaComponent,
        TituloCardComponent,
        TituloCardSimplesComponent,
        ValidacaoFormularioComponent,
        ResumoDadosGeraisComponent,
        ResumoAcoesDespesasComponent,
        ResumoRepassesComponent,
        ResumoParticipesComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        GeneralFontAwesomeModule,
        MaterialModule,
        NgxMaskModule.forRoot(maskConfigFunction),
        NgxSpinnerModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [
        NgxMaskModule,
        FormataConvenioPipe,
        SafePipe,
        CommonModule,
        FontAwesomeModule,
        GeneralFontAwesomeModule,
        NgxSpinnerModule,
        MaterialModule,
        BarraAcaoComponent,
        BarraConvenioComponent,
        BreadcrumbsComponent,
        ConfirmComponent,
        NextStepConfirmComponent,
        DatepickerComponent,
        DialogComponent,
        HintComponent,
        InfoUsuarioComponent,
        InputComponent,
        ListaCardComponent,
        ProjetoRowCardComponent,
        SelectComponent,
        StepperComponent,
        TextareaComponent,
        TituloCardComponent,
        TituloCardSimplesComponent,
        ValidacaoFormularioComponent,
        ResumoDadosGeraisComponent,
        ResumoAcoesDespesasComponent,
        ResumoRepassesComponent,
        ResumoParticipesComponent
    ],
})
export class SharedModule {}
