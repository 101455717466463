import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ArrayService {
    public converterListaParaLinhas<T>(list: T[], quantidadeElementosPorLinha: number): T[][] {
        const linhas: T[][] = [];

        let indiceElemento: number;
        let indiceLinha: number;

        for (indiceElemento = 0, indiceLinha = -1; indiceElemento < list.length; indiceElemento++) {
            if (indiceElemento % quantidadeElementosPorLinha === 0) {
                indiceLinha++;
                linhas[indiceLinha] = [];
            }

            linhas[indiceLinha].push(list[indiceElemento]);
        }

        return linhas;
    }
}
