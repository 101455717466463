<mat-card>
    <div class="mt-20 ml-20 mr-20 mb-40">
        <h3 *ngIf="idAditivoDescricao === 0 || idAditivoDescricao === undefined"
            style="opacity: 60%; color: black; margin-top: 1em;">
            Conferir todos os partícipes cadastrados - Projeto {{ projetoId }}
        </h3>
        <h3 *ngIf="idAditivoDescricao !== 0 && idAditivoDescricao !== undefined"
            style="opacity: 60%; color: black; margin-top: 1em;">
            Conferir todos os partícipes cadastrados - Projeto {{ projetoId }} - {{ idAditivoDescricao }}
        </h3>
        <br>
        <!-- inicio tablela -->
        <div class="m-10" *ngIf="!participes || participes.length === 0">Não há partícipe definido</div>

        <div class="m-10">
            <table *ngIf="participes" mat-table [dataSource]="dataSourceParticipe"
                   class="mat-elevation-z8" multiTemplateDataRows>
                <!-- Coluna Id -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>
                        Id
                    </th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex;">
                        {{ i+1 }}
                    </td>
                </ng-container>

                <!-- Coluna Nome da ação -->
                <ng-container matColumnDef="desc_fornecedores">
                    <th mat-header-cell *matHeaderCellDef>
                        Nome da Entidade
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.desc_fornecedores }}
                    </td>
                </ng-container>

                <!-- Coluna destinada aos botões -->
                <ng-container matColumnDef="acoes">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="acoes">
                        <button class="icon-button" mat-icon-button aria-label="visualizar partícipe"
                                matTooltip="visualizar partícipe">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element"
                        [attr.colspan]="exibirColunasParticipe.length">
                        <div class="example-element-detail"
                             [@detailExpand]="element == elementoExpandido ? 'expanded' : 'collapsed'">
                            <div style="max-width: 250px; width: 100%">
                                <fieldset class="input-detail">
                                    <legend>CEP</legend>
                                    <div>
                                        <p>{{ element.desc_cep }}</p>
                                    </div>
                                </fieldset>
                            </div>
                            <fieldset class="input">
                                <legend>Endereço - Av/Rua</legend>
                                <div>
                                    <p>{{ element.desc_endereco }}</p>
                                </div>
                            </fieldset>
                            <div class="flex-container-60 form-field" style="width: calc(100%)">
                                <div class="flex-item-40">
                                    <fieldset class="input">
                                        <legend>Número</legend>
                                        <div>
                                            <p>{{ element.cdn_num }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="flex-item-60" style="margin-left: 2em">
                                    <fieldset class="input">
                                        <legend>Bairro</legend>
                                        <div>
                                            <p>{{ element.desc_bairro }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="flex-container-60 form-field" style="width: calc(100%)">
                                <div class="flex-item-60">
                                    <fieldset class="input">
                                        <legend>Cidade</legend>
                                        <div>
                                            <p>{{ element.desc_municipio }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="flex-item-40" style="margin-left: 2em">
                                    <fieldset class="input">
                                        <legend>CNPJ</legend>
                                        <div>
                                            <p>{{ element.desc_cnpj }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="flex-container-60 form-field" style="width: calc(100%)">
                                <div class="flex-item-60">
                                    <fieldset class="input">
                                        <legend>Inscrição Estadual</legend>
                                        <div>
                                            <p>{{ element.desc_inscricao }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <fieldset class="input">
                                <legend>Representante</legend>
                                <div>
                                    <p>{{ element.desc_representante }}</p>
                                </div>
                            </fieldset>
                            <fieldset class="input">
                                <legend>Forma de Representação</legend>
                                <div>
                                    <p>{{ element.desc_forma_repres }}</p>
                                </div>
                            </fieldset>
                            <div class="flex-container-60 form-field" style="width: calc(100%)">
                                <div class="flex-item-40">
                                    <fieldset class="input">
                                        <legend>CPF</legend>
                                        <div>
                                            <p>{{ element.desc_cpf }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="flex-item-60" style="margin-left: 2em">
                                    <fieldset class="input">
                                        <legend>Carteira de Identidade</legend>
                                        <div>
                                            <p>{{ element.desc_ci }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="flex-container-60 form-field" style="width: calc(100%)">
                                <div class="flex-item-40">
                                    <fieldset class="input">
                                        <legend>Orgão Expedidor</legend>
                                        <div>
                                            <p>{{ element.desc_ci_expeditor }}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="exibirColunasParticipe"></tr>
                <tr mat-row *matRowDef="let element; columns: exibirColunasParticipe"
                    class="example-element-row"
                    [class.example-expanded-row]="elementoExpandido === element"
                    (click)="elementoExpandido = elementoExpandido === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    class="example-detail-row"></tr>
            </table>
        </div>
        <!-- fim tabela -->
    </div>
</mat-card>
