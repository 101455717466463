import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import { Responsavel } from './../../models/projetos/responsavel';
import {catchError, map, take} from 'rxjs/operators';
import { HttpHelper } from '@app/helpers/http-helper';
import { ArrayHelper } from '@app/helpers/array-helper';
import { ResponsavelLegalParceiro } from '@data/models/projetos/responsavel-legal-parceiro';

@Injectable({
    providedIn: 'root',
})
export class ResponsavelService {
    private readonly url = '/api/responsavel';
    private readonly url_responsavel_legal = '/api/responsavel-legal';

    constructor(private http: HttpClient) {}

    getResponsaveis(numeroPessoaJuridica: string): Observable<Responsavel[]> {
        const params = {
            num_pessoa_jurid: numeroPessoaJuridica,
        };
        return this.http
            .get<{ responsavel: Responsavel | Responsavel[] }>(
                `${this.url}/${HttpHelper.pegarDataFormatoIso()}`,
                { params }
            )
            .pipe(
                take(1),
                map((res) => {
                    if (res === null) {
                        return [];
                    }
                    if (Array.isArray(res.responsavel)) {
                        return ArrayHelper.normalizar<Responsavel>(
                            res.responsavel
                        );
                    } else if (res.responsavel !== undefined) {
                        return [
                            new Responsavel(
                                res.responsavel.cod_crc,
                                res.responsavel.cpf,
                                res.responsavel.email,
                                res.responsavel.ind_vinculo,
                                res.responsavel.nome,
                                res.responsavel.num_pessoa_jurid,
                                res.responsavel.observacao,
                                res.responsavel.telefone,
                                res.responsavel.responsavel
                            ),
                        ];
                    } else {
                        return [];
                    }
                }),
                catchError((error) => {
                    return throwError({
                        body: params,
                        error: error,
                    });
                })
            );
    }

    getResponsaveisLegaisParceiro(numeroPessoaJuridica: string): Observable<ResponsavelLegalParceiro[]> {
        const params = {
            num_pessoa_jurid: numeroPessoaJuridica,
        };
        return this.http
            .get<any>(
                `${this.url_responsavel_legal}/${HttpHelper.pegarDataFormatoIso()}`,
                { params }
            )
            .pipe(
                take(1),
                map((res) => {
                    return res;
                }),
                catchError((error) => {
                    return throwError({
                        body: params,
                        error: error,
                    });
                })
            );
    }
}
