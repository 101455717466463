import { DespesaRetorno } from './../../models/projetos/despesa-retorno';
import { IUpload } from './../../models/projetos/upload';
import { CotacaoFornecedor, CotacaoFornecedores } from './../../models/projetos/cotacao-despesa';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Acao } from '../../models/projetos/acao';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { timeout, take, map, catchError } from 'rxjs/operators';
import { IRetorno } from '@data/models/suporte/iretorno';
import { Despesa } from './../../models/projetos/despesa';
import { ArrayHelper } from './../../../core/helpers/array-helper';
import { DespesaTipo } from './../../models/projetos/despesa-tipo';
import {UtilHelper} from "@app/helpers/util-helper";

@Injectable({
    providedIn: 'root',
})
export class DespesasCotacoesService {
    private readonly url = '/api/projeto/despesa';
    private readonly urlBuscaCotacao = '/api/projeto/cotacao';
    private readonly urlExcluirCotacao = '/api/projeto/cotacao/excluir-cotacao';
    private readonly urlComUpload = 'api/projeto/despesa/upload-termo';


    constructor(private http: HttpClient) { }

    public carregarAcaoPeloIdProjeto(
        idProjeto: string,
        cdnEtapa: string = '1'
    ): Observable<Acao | Acao[]> {
        const params = {
            cdn_projeto: idProjeto,
            cdn_etapa: cdnEtapa,
        };
        return this.http
            .get<{ acao: Acao | Acao[] }>(
                `${this.url}/${HttpHelper.pegarDataFormatoIso()}`,
                {
                    params,
                }
            )
            .pipe(
                // timeout(2500),
                take(1),
                map((res) => {
                    if (res === null) {
                        return [];
                    }
                    if (Array.isArray(res.acao)) {
                        return ArrayHelper.normalizar<Acao>(res.acao);
                    } else if (res.acao !== undefined) {
                        return [
                            new Acao(
                                res.acao.cdn_etapa,
                                res.acao.dsc_acao,
                                res.acao.seq_acao,
                                ArrayHelper.normalizar<Despesa>(
                                    res.acao.despesas
                                )
                            ),
                        ];
                    } else {
                        return [];
                    }
                }),
                catchError((error) => {
                    return throwError({
                        body: params,
                        error: 'Não foi possível carregar a lista de ações.',
                    });
                })
            );
    }

    public deletarDespesa(despesa: Despesa): Observable<IRetorno> {
        const body = new URLSearchParams();

        body.set('rowid', despesa.rowid);

        return this.http
            .delete<{ retorno: IRetorno }>(`${this.url}?${body}`, {})
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: JSON.stringify(body.toString()),
                        error: 'Não foi possível excluir a despesa.',
                    });
                })
            );
    }

    private montarBodyParametros(
        despesa: Despesa,
        idProjeto: string,
        idAcao: string,
        tipoAcao: TipoAcao,
        cnpjParceiro: string,
        arquivo: File,
        temAnexoParaSubir: boolean
    ): FormData {
        const fd = new FormData();

        fd.append('cdn_despesa', despesa.cdn_despesa);
        fd.append('qtd_despesa', '1');
        fd.append('cdn_projeto', idProjeto);
        fd.append('vlr_unit_despesa', despesa.vlr_total);
        fd.append('cdn_etapa', '1');
        fd.append('vlr_desp_sebrae', despesa.vlr_desp_sebrae);
        fd.append('vlr_desp_parceiro', despesa.vlr_desp_parceiro);
        fd.append('obs_despesa', despesa.obs_despesa);
        fd.append('seq_acao', idAcao);
        fd.append('desc_despesa', despesa.desc_despesa);
        fd.append('idi_tipo_despesa', despesa.idi_tipo_despesa);
        fd.append('desc_fundam_legal', despesa.desc_fundam_legal);
        // fd.append('local_arq_termo', despesa.local_arq_termo);
        // fd.append('nome_arq_termo', despesa.nome_arq_termo);
        fd.append('arquivo_anexo', temAnexoParaSubir === true ? 'true' : 'false');
        if (temAnexoParaSubir === true) {
            fd.append('file', arquivo, arquivo.name.replace(/ +/g, '_'));
            fd.append('cnpj_parceiro', cnpjParceiro);
        }

        if (tipoAcao === TipoAcao.ALTERAR) {
            fd.append('seq_despesa', despesa.seq_despesa);
            fd.append('rowid', despesa.rowid);
            fd.append('acao', 'alterar');
        }

        if (tipoAcao === TipoAcao.INCLUIR) {
            fd.append('acao', 'incluir');
        }

        return fd;
    }

    public incluirDespesa(
        despesa: Despesa,
        idProjeto: string,
        idAcao: string,
        cnpjParceiro: string,
        arquivo: File,
        temAnexoParaSubir: boolean
    ): Observable<DespesaRetorno> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'multipart/form-data'
            ),
        };

        const fd = this.montarBodyParametros(
            despesa,
            idProjeto,
            idAcao,
            TipoAcao.INCLUIR,
            cnpjParceiro,
            arquivo,
            temAnexoParaSubir
        );

        return this.http
            .post<{ retorno: DespesaRetorno }>(
                this.urlComUpload,
                fd,
                options
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: UtilHelper.formDataToString(fd),
                        error: 'Não foi possível incluir a despesa.',
                    });
                })
            );
    }

    public atualizarDespesa(
        despesa: Despesa,
        idProjeto: string,
        idAcao: string,
        cnpjParceiro: string,
        arquivo: File,
        temAnexoParaSubir: boolean
    ): Observable<DespesaRetorno> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'multipart/form-data'
            ),
        };

        const fd = this.montarBodyParametros(
            despesa,
            idProjeto,
            idAcao,
            TipoAcao.ALTERAR,
            cnpjParceiro,
            arquivo,
            temAnexoParaSubir
        );
        return this.http
            .post<{ retorno: DespesaRetorno }>(
                this.urlComUpload,
                fd,
                options
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: UtilHelper.formDataToString(fd),
                        error: 'Não foi possível alterar a despesa.',
                    });
                })
            );
    }

    pegarTiposDespesa(): Observable<DespesaTipo[]> {
        return this.http
            .get<{ despesa: DespesaTipo | DespesaTipo[] }>(
                `${this.url}-tipo/${HttpHelper.pegarDataFormatoIso()}`
            )
            .pipe(
                // timeout(2500),
                take(1),
                map((res) => {
                    if (res === null) {
                        return [];
                    }
                    if (Array.isArray(res.despesa)) {
                        return ArrayHelper.normalizar<DespesaTipo>(res.despesa);
                    } else if (res.despesa !== undefined) {
                        return [
                            new DespesaTipo(
                                res.despesa.cdn_despesa,
                                res.despesa.dsc_despesa,
                                res.despesa.idi_tipo_despesa,
                                res.despesa.idi_pagador
                            ),
                        ];
                    } else {
                        return [];
                    }
                }),
                catchError((error) => {
                    return throwError({
                        body: null,
                        error: 'Não foi possível carregar os itens do campo tipo de despesa.',
                    });
                })
            );
    }

    public carregarCotacoes(
        idProjeto: string,
        idEtapa: string,
        idAcao: string,
        idDespesa: string,
    ): Observable<CotacaoFornecedores> {
        const params = {
            cdn_projeto: idProjeto,
            cdn_etapa: idEtapa,
            seq_acao: idAcao,
            seq_despesa: idDespesa
        };
        return this.http
            .get<CotacaoFornecedores>(
                `${this.urlBuscaCotacao}/${HttpHelper.pegarDataFormatoIso()}`,
                {
                    params,
                }
            ).pipe(catchError((error) => {
                return throwError({
                    body: params,
                    error: error,
                });
            }));
    }

    private montarBodyParametrosCotacao(
        cotacao: CotacaoFornecedor,
        idProjeto: string,
        idAcao: string,
        idEtapa: string,
        idDespesa: string,
        cnpjParceiro: string,
        arquivo: File,
        acao: string
    ): FormData {
        const fd = new FormData();

        const chavePDF = idAcao + "-" + idDespesa + "-" + cotacao.cnpj_cpf+ "-";

        fd.append('acao', acao);
        fd.append('cdn_projeto', idProjeto);
        fd.append('cdn_etapa', idEtapa);
        fd.append('seq_acao', idAcao);
        fd.append('seq_despesa', idDespesa);
        fd.append('cnpj_cpf', cotacao.cnpj_cpf);
        fd.append('nome_fornec', cotacao.nome_fornec);
        fd.append('valor_cotacao', cotacao.valor_cotacao);

        fd.set('nome_arq_orcto', cotacao.nome_arq_orcto);
        fd.append('cnpj_parceiro', cnpjParceiro);

        if (!arquivo) {// TODO quando mandar local_arq_orcto não necessitar do arquivo
            fd.set('local_arq_orcto', cotacao.loc_arq_orcto);
        }else{
            fd.append('file', arquivo, chavePDF + arquivo.name.replace(/ +/g, '_'));
        }

        return fd;
    }

    public incluirCotacao(
        cotacao: CotacaoFornecedor,
        idProjeto: string,
        idAcao: string,
        idEtapa: string,
        idDespesa: string,
        cnpjParceiro: string,
        arquivo: File

    ): Observable<IRetorno> {

        const acao = 'cotacao_fornec';
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'multipart/form-data'
            ),
        };

        const fd = this.montarBodyParametrosCotacao(
            cotacao, idProjeto, idAcao, idEtapa, idDespesa, cnpjParceiro, arquivo, acao
        );

        console.log(fd);
        return this.http
            .post<{ retorno: IRetorno }>(
                this.urlComUpload,
                fd,
                options
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: UtilHelper.formDataToString(fd),
                        error: 'Não foi possível incluir a cotação.',
                    });
                })
            );
    }

    public deletarCotacao(
        idEtapa: string,
        idProjeto: string,
        idAcao: string,
        idDespesa: string,
        cnpj: string
    ): Observable<IRetorno> {
        const body = new URLSearchParams();

        body.set('cdn_etapa', idEtapa);
        body.set('cdn_projeto', idProjeto);
        body.set('seq_acao', idAcao);
        body.set('seq_despesa', idDespesa);
        body.set('cnpj_cpf', cnpj);

        return this.http
            .delete<{ retorno: IRetorno }>(`${this.urlExcluirCotacao}?${body}`, {})
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: JSON.stringify(body.toString()),
                        error: 'Não foi possível excluir a cotação.',
                    });
                })
            );
    }

    public uploadTermo(fd: FormData): Observable<IUpload> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'multipart/form-data'
            ),
        };
        return this.http
            .post<{ retorno: IUpload }>(
                this.urlComUpload,
                fd,
                options
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError(
                        `Não foi possível salvar os dados do projeto.\nMensagem de erro do backend: ${error.message
                        }${error.status !== undefined
                            ? ` - Código de erro do backend: ${error.status}`
                            : ''
                        }`
                    );
                })
            );
    }

}

export enum TipoAcao {
    ALTERAR,
    INCLUIR,
    EXCLUIR,
}
