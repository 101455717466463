<mat-card class="border-box-top-accent">
    <mat-card-content>
        <!-- INICIO - topo mat-card -->
        <div class="barra-titulo-row">
            <div class="item justify-start">{{ titulo }}</div>
            <div class="item">{{ quantidadeTexto }}</div>
            <div class="item" style="padding-left: 1em">
                <div class="lista-quantidade">
                    <span>{{ quantidadeValor }}</span>
                </div>
            </div>
            <div class="item" style="padding-left: 1em">
                <mat-form-field
                    class="pesquisa"
                    color="accent"
                    appearance="outline"
                >
                    <input
                        matInput
                        placeholder="{{ inputPlaceholder }}"
                        (keyup)="chamarEventoKeypress($event)"
                    />
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <!-- FIM - topo mat-card -->
        <!-- INICIO - listagem de projetos -->
        <div class="mat-card-lista-projeto">
            <ng-content></ng-content>
        </div>
        <!-- FIM - listagem de projetos -->
    </mat-card-content>
</mat-card>
