import { Injectable } from '@angular/core';

import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenService } from './token.service';
import { DialogService } from '@shared/dialog/dialog.service';

export interface Configuracao {
    apiBaseUrl: string;
    versao: string;
}

@Injectable({
    providedIn: 'root',
})
export class ConfiguracaoService {
    private _config: Configuracao = { apiBaseUrl: '', versao: '' };

    constructor(
        private http: HttpClient,
        private _tokenService: TokenService,
        private _dialogService: DialogService
    ) {}

    public carregarConfiguracao(): void {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
        this.http
            .get<Configuracao>('./assets/config/app.config.json', { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError({
                        body: null,
                        error: error,
                    });
                })
            )
            .subscribe(
                (config) => {
                    this._config = config;
                    this._tokenService.pegarToken();
                },
                (erro) => {
                    this._dialogService.exibir({
                        titulo: 'Atenção',
                        conteudo: erro.error,
                        jsonChamada: JSON.stringify(erro.body),
                        isErroServidor: true,
                    });
                }
            );
    }

    get config(): Configuracao {
        return this._config;
    }

    getApiUrl(api: string): string {
        let url = api;

        if (api.startsWith('/api/')) {
            url = api.substring(1);
        }

        if (url.startsWith('api/')) {
            url = `${this._config.apiBaseUrl}${url}`;
        }

        return url;
    }
}
