<div class="container" style="display: block">
    <div class="conteudo">
        <h3 style="opacity: 60%; color: black; margin-top: 1em;">
           Descrições das despesas
        </h3>
        <div>
            <fieldset class="input">
                <legend>Despesa</legend>
                <div>
                    <p>{{ despesa.desc_despesa }}</p>
                </div>
            </fieldset>
            <p>Participação</p>
            <mat-slide-toggle color="accent" disabled>{{
                despesa.idi_tipo_despesa === '1'
                ? "Econômica"
                : "Financeira"
                }}</mat-slide-toggle>
            <div class="flex-container-30" style="max-width: 390px">
                <fieldset class="input">
                    <legend>Tipo</legend>
                    <div>
                        <p>{{ despesa.dsc_despesa }}</p>
                    </div>
                </fieldset>
            </div>
            <div class="flex-container-30" style="max-width: 500px">
                <div class="flex-item-30">
                    <fieldset class="input">
                        <legend>Valor Parceiro</legend>
                        <div>
                            <p>{{ despesa.vlr_desp_parceiro }}</p>
                        </div>
                    </fieldset>
                </div>
                <div class="flex-item-30">
                    <fieldset class="input">
                        <legend>Valor Sebrae</legend>
                        <div>
                            <p>{{ despesa.vlr_desp_sebrae }}</p>
                        </div>
                    </fieldset>
                </div>
                <div class="flex-item-30">
                    <fieldset class="input">
                        <legend>Valor Total</legend>
                        <div>
                            <p>{{ despesa.vlr_total }}</p>
                        </div>
                    </fieldset>
                </div>
            </div>
            <fieldset>
                <legend>Fundamentação Legal</legend>
                <div class="textarea">
                    <p>{{ despesa.desc_fundam_legal }}</p>
                </div>
            </fieldset>
            <div style="max-width: 520px">
                <fieldset class="input">
                    <legend>Termo de Referência</legend>
                    <div (click)="abrirTermoSalvo()">
                        <p>{{ despesa.nome_arq_termo }}</p>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>

    <!-- inicio tablela cotacoes -->
    <div *ngIf="dataSourceCotacao.length > 0">
        <h3 style="opacity: 60%; color: black; margin-top: 1em;">
            Propostas de orçamento
        </h3>
        <div class="tabela">
            <table mat-table [dataSource]="dataSourceCotacao" class="mat-elevation-z8">
                <!-- Coluna Despesa -->
                <ng-container matColumnDef="cnpj_cpf" >
                    <th mat-header-cell *matHeaderCellDef >CNPJ/CPF</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.cnpj_cpf.length === 14"> {{ element.cnpj_cpf | mask:"00.000.000/0000-00" }} </span>
                        <span *ngIf="element.cnpj_cpf.length === 11"> {{ element.cnpj_cpf | mask:"000.000.000-00" }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nome_fornec">
                    <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.nome_fornec }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nome_arq_orcto">
                    <th mat-header-cell *matHeaderCellDef>Orçamento <i style="color: red">(Clique para visualizar)</i></th>
                    <td mat-cell *matCellDef="let element" (click)="abrirCotacaoSalva(element.cnpj_cpf)">
                        {{ retornaNomeArquivo(element.nome_arq_orcto,element.cnpj_cpf) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="valor_cotacao">
                    <th mat-header-cell *matHeaderCellDef>
                        Valor
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.valor_cotacao.replace(',','.') | currency:'':''}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsCotacao"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsCotacao"></tr>
            </table>
        </div>
    </div>
    <!-- fim tabela cotacoes -->

    <mat-divider style="margin: 24px 0 12px;"></mat-divider>
    <mat-dialog-actions align="end">
        <button mat-button class="mat-raised-button mat-primary" mat-dialog-close>FECHAR</button>
    </mat-dialog-actions>

</div>
