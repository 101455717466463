<div class="container">
    <div
        *ngIf="icone !== 'sem-icone'"
        class="icone"
        [class.icone-conteudo]="!data.titulo"
    >
        <mat-icon inline="true" [color]="isErroServidor ? 'warn' : undefined">{{
            icone
        }}</mat-icon>
    </div>
    <div class="conteudo">
        <h1 *ngIf="data.titulo" mat-dialog-title>{{ data.titulo }}</h1>

        <div *ngIf="data.lista">
            <span
                style="overflow-wrap: anywhere; white-space: normal"
                [innerHTML]="data.conteudo"
            ></span>
            <ul>
                <li *ngFor="let opcao of data.lista">{{ opcao }}</li>
            </ul>
        </div>

        <div *ngIf="!showErro && !data.lista" mat-dialog-content style="white-space: pre-line">
            <span
                [innerHTML]="
                    isErroServidor
                        ? 'Ocorreu um erro ao processar sua solicitação. <br/>Se você deseja obter mais informações, você pode clicar no botão \'Ver Detalhes\' abaixo.'
                        : data.conteudo ===
                            'Sequencia Prestação de Conta não possui lançamento de Resultado.'
                          ? 'Salvar Rascunho antes de anexar'
                          : data.conteudo
                "
            ></span>
        </div>

        <div *ngIf="data.jsonRetorno && showErro && !data.lista" mat-dialog-content>
            <span
                style="overflow-wrap: anywhere; white-space: normal"
                [innerHTML]="
                    data.jsonRetorno
                        ? 'Retorno: ' + quebrarLinha(data.jsonRetorno)
                        : quebrarLinha(data.conteudo)
                "
            ></span>
        </div>

        <div *ngIf="isErroServidor && showErro && !data.lista" mat-dialog-content>
            <span
                style="overflow-wrap: anywhere; white-space: normal"
                [innerHTML]="
                    data.jsonChamada
                        ? quebrarLinha(data.conteudo) +
                          quebrarLinha('\nChamada: ') +
                          quebrarLinha(data.jsonChamada)
                        : quebrarLinha(data.conteudo)
                "
            ></span>
            <br />
        </div>
        <div mat-dialog-actions align="end">
            <button
                *ngIf="isErroServidor || data.jsonRetorno"
                color="primary"
                mat-button
                (click)="verErro()"
            >
                {{ showErro ? "OCULTAR DETALHES" : "VER DETALHES" }}
            </button>
            <button color="primary" mat-button (click)="fechar()">
                FECHAR
            </button>
        </div>
    </div>
</div>
