import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, interval } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from './../../data/models/suporte/token';

declare global {
    interface Date {
        addMinutos(days: number, useThis?: boolean): void;
    }
}
// tslint:disable-next-line: space-before-function-paren
Date.prototype.addMinutos = function (minutos): void {
    this.setMinutes(this.getMinutes() + minutos);
};

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private readonly url = '/api/token';
    public readonly tokenLocalStorage = 'token';

    private _tokenSubject: BehaviorSubject<Token | null>;
    private _token: Observable<Token | null>;

    constructor(private http: HttpClient) {
        const dadosToken = localStorage.getItem(this.tokenLocalStorage);
        let token: Token | null = null;

        if (dadosToken) {
            token = JSON.parse(dadosToken);
        }

        this._tokenSubject = new BehaviorSubject<Token | null>(token);
        this._token = this._tokenSubject.asObservable();
    }

    private verificarTokenAtual(): string | boolean {
        if (this.token) {
            const data = new Date();
            const dataExpiracao = new Date(this.token.dataExpiracao);
            if (dataExpiracao > data) {
                return this.token.token;
            }
        }
        return false;
    }

    public get token(): Token | null {
        return this._tokenSubject.value;
    }

    pegarTokenNumero(): string {
        if (this._tokenSubject.value !== null) {
            return `Basic:${(this._tokenSubject.value as Token).token}`;
        }
        return `Basic:`;
    }

    private dataToken(token: string): Token {
        const dataGeracao = new Date();
        const dataExpiracao = new Date();
        dataExpiracao.addMinutos(3);
        return {
            token,
            dataGeracao: String(dataGeracao),
            dataExpiracao: String(dataExpiracao),
        } as Token;
    }

    public pegarToken(): void {
        interval(3000).subscribe(() => {
            const retornoVerificacao = this.verificarTokenAtual();

            if (!retornoVerificacao) {
                const options = {
                    headers: new HttpHeaders().set(
                        'Content-Type',
                        'application/x-www-form-urlencoded'
                    ),
                };
                const body = new URLSearchParams();
                body.set('action', 'client_credentials');
                body.set('web_context', 'request_convenios');

                this.http
                    .post<{ retorno: Token }>(
                        this.url,
                        body.toString(),
                        options
                    )
                    .pipe(
                        map((res) => res.retorno.token),
                        take(1)
                    )
                    .subscribe((res) => {
                        localStorage.setItem(
                            this.tokenLocalStorage,
                            JSON.stringify(this.dataToken(res))
                        );
                        this._tokenSubject.next(this.dataToken(res));
                    });
            }
        });
    }

    public removerToken(): void {
        localStorage.removeItem(this.tokenLocalStorage);
        this._tokenSubject.next(null);
    }
}
