import { DatePipe } from '@angular/common';
import { Convenio } from './../../data/models/projetos/convenio';
import { Component, Input, OnInit } from '@angular/core';
import { Projeto } from '@data/models/projetos/projeto';

@Component({
    selector: 'sbcnv-barra-convenio',
    templateUrl: './barra-convenio.component.html',
    styleUrls: ['./barra-convenio.component.scss'],
})
export class BarraConvenioComponent implements OnInit{
    @Input() public convenio!: Convenio;
    @Input() public projeto!: Projeto;
    @Input() public dataLimitePC: string = '';

    constructor() {}

    ngOnInit(): void {
        let array_data = this.convenio.dat_val_fim.split('/');
        let data = array_data[2] + '-' + array_data[1] + '-' + array_data[0];
        const dataInicial = new Date(data);
        const dataFinal = new Date(dataInicial.getTime() + 31 * 24 * 60 * 60 * 1000);
        this.dataLimitePC = dataFinal.toLocaleDateString('pt-BR');
    }
}
