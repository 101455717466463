export class Login {
    public cnpj = '';
    public cod_usuario = '';
    public cpf = '';
    public email = '';
    public empresa = '';
    public matricula = '';
    public mensagem = '';
    public nome = '';
    public pessoa_jurid = '';
    public tipo = '';
    public restr_cria_proj = '';
    public alter_senha = '';
    public expir_senha = '';
    public grp_usuario = '';
    public cod_convenio = '';
}
