import { Component, Input } from '@angular/core';

import {
    FormControl,
    FormGroup,
    FormArray,
    AbstractControl,
} from '@angular/forms';
import { Helpers } from '../helpers';
import { ValidacaoFormulario } from './validacao-formulario';

@Component({
    selector: 'sbcnv-validacao-formulario',
    templateUrl: './validacao-formulario.component.html',
    styleUrls: ['./validacao-formulario.component.scss'],
})
export class ValidacaoFormularioComponent {
    @Input() public formulario!: FormGroup;
    @Input() public controlName = '';
    @Input() label = '';

    constructor() {}

    get campo(): AbstractControl {
        return Helpers.getFormControl(this.controlName, this.formulario);
    }

    get errorMessage(): string {
        // if (this.controlName !== '') {
        for (const propertyName in this.campo.errors) {
            if (
                this.campo.errors.hasOwnProperty(propertyName) &&
                this.campo.touched
            ) {
                return ValidacaoFormulario.pegarMensagemErro(
                    this.label,
                    propertyName,
                    this.campo.errors[propertyName]
                );
            }
        }
        // }
        return '';
    }
}
