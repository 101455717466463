import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, tap, take, catchError } from 'rxjs/operators';
import { Login } from '@data/models/autenticacao/login';
import { ConfiguracaoService } from './configuracao.service';
import { TokenService } from './token.service';
import { ObjectHelper } from '@app/helpers/object-helper';

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoService {
    private readonly url = '/api/autenticacao';
    private readonly chaveUsuarioLocalStorage = 'usuario';

    private _usuarioSubject: BehaviorSubject<Login | null>;
    private _usuario: Observable<Login | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private _tokenService: TokenService,
        private configuracaoService: ConfiguracaoService
    ) {
        const dadosUsuario = localStorage.getItem(
            this.chaveUsuarioLocalStorage
        );
        let usuario: Login | null = null;

        if (dadosUsuario) {
            usuario = JSON.parse(dadosUsuario);
        }

        this._usuarioSubject = new BehaviorSubject<Login | null>(usuario);
        this._usuario = this._usuarioSubject.asObservable();
    }

    getAuthorizationToken(): string {
        return this._tokenService.pegarTokenNumero();
    }

    public get usuario(): Login | null {
        return this._usuarioSubject.value;
    }

    pegarUsuarioTipo(): number {
        if (!this._usuarioSubject.value) {
            throw new Error(
                'Não foi possível pegar as informações do usuário logado no sistema.'
            );
        }

        const usuarioTipoMap = new Map();
        usuarioTipoMap.set('Parceiro', 1);
        usuarioTipoMap.set('sebrae', 2);

        return usuarioTipoMap.get(this._usuarioSubject.value.tipo);
    }

    login(body: any): Observable<Login> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'application/x-www-form-urlencoded'
            ),
        };

        return this.http
            .post<{ retorno: Login }>(
                `${this.url}/login`,
                body.toString(),
                options
            )
            .pipe(
                map((res: any) => res.hasOwnProperty('retorno') ? res.retorno : res),
                // @ts-ignore
                tap((usuario: any) => {
                    if (usuario.hasOwnProperty('mensagem') && usuario.mensagem.toUpperCase() === 'OK') {
                        localStorage.setItem(
                            this.chaveUsuarioLocalStorage,
                            JSON.stringify(usuario)
                        );
                        this._usuarioSubject.next(
                            ObjectHelper.recreate(Login, usuario)
                        );
                    } else {
                        return throwError({
                            body: body.toString(),
                            error: usuario,
                        });
                    }
                }),
                catchError((error) => {
                    return throwError({
                        body: body.toString(),
                        error: error,
                    });
                }),
                take(1)
            );
    }

    logout(): void {
        localStorage.removeItem(this.chaveUsuarioLocalStorage);
        this._usuarioSubject.next(null);

        this.router.navigate(['/autenticacao/login'], {
            skipLocationChange: true,
        });
    }
}
