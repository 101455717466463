import { Acao } from './../../../../../data/models/projetos/acao';
import { DespesasCotacoesService } from '@data/service/projetos/despesas-cotacoes.service';
import { Despesa } from './../../../../../data/models/projetos/despesa';
import { FormControl } from '@angular/forms';
import { Component, Input, Inject, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CotacaoFornecedor } from '@data/models/projetos/cotacao-despesa';
import {DialogService} from "@shared/dialog/dialog.service";
// import { GeraViaParams } from '@data/models/clientes/gera-via-params';

@Component({
    selector: 'sbmg-dialog-despesa',
    templateUrl: './dialog-despesa.component.html',
    styleUrls: ['./dialog-despesa.component.scss'],
})
export class DialogDespesaComponent implements OnInit {
    @Input() despesa!: Despesa;
    @Input() acao!: Acao;
    @Input() projetoId!: string;
    public cotacoesSelecionadas: Array<CotacaoFornecedor> = [];

    displayedColumnsCotacao: string[] = [
        'cnpj_cpf',
        'nome_fornec',
        'nome_arq_orcto',
        'valor_cotacao',
    ];
    dataSourceCotacao: CotacaoFornecedor[] = [];

    constructor(
        public _dialogRef: MatDialogRef<DialogDespesaComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            despesa: Despesa;
            acao: Acao;
            idProjeto: string;
        },
        private _despesasCotacoesService: DespesasCotacoesService,
        private _dialogService: DialogService,
    ) {
        this.despesa = this.data.despesa;
        this.acao = this.data.acao;
        this.projetoId = this.data.idProjeto;
     }

    ngOnInit(): void {
        this.carregarCotacoes(this.acao.cdn_etapa, this.acao.seq_acao, this.despesa.seq_despesa);
    }

    abrirCotacaoSalva(cnpj: string): void {
        let url = '';
        this.dataSourceCotacao.forEach(element => {
            if (element.cnpj_cpf === cnpj) {
                url = (element as CotacaoFornecedor).loc_arq_orcto;
            }
        });
        if (url.length > 0) {
            window.open(url, '_blank', 'noopener');
        }
    }

    abrirTermoSalvo(): void {
        const url = this.despesa.local_arq_termo;
        if (url.length > 0) {
            window.open(url, '_blank', 'noopener');
        }
    }

    retornaNomeArquivo(loc :string ,cpf_cnpj: string): string {

        let index = "-"+cpf_cnpj+"-";
        let posicao = loc.indexOf(index);
        let nomearquivo = loc.substring(posicao, loc.length).replace(index,"");

        nomearquivo = this.tratarFileName(nomearquivo);
        console.log('nomearquivo', nomearquivo);

        return nomearquivo
    }

    private carregarCotacoes(idEtapa: string, idAcao: string, idDespesa: string): void {
        this._despesasCotacoesService
            .carregarCotacoes(this.projetoId, idEtapa, idAcao, idDespesa)
            .subscribe((resposta) => {
                this.dataSourceCotacao = [];
                if (resposta.cotacao_fornecedor) {
                    if (Array.isArray(resposta.cotacao_fornecedor)){
                    this.cotacoesSelecionadas = resposta.cotacao_fornecedor;
                    this.dataSourceCotacao = this.cotacoesSelecionadas;
                    } else{
                    this.cotacoesSelecionadas = [];
                    this.cotacoesSelecionadas.push(resposta.cotacao_fornecedor);
                    this.dataSourceCotacao = this.cotacoesSelecionadas;
                }}
            }, (erro) => {
                this._dialogService.exibir({
                    titulo: 'Atenção',
                    conteudo: erro.error,
                    jsonChamada: JSON.stringify(erro.body),
                    isErroServidor: true,
                });
            });
    }

    public fechar(): void {
        this._dialogRef.close();
    }

    tratarFileName(nome: string): string{
        const fileName = nome.replace(/ +/g, '_');
        return fileName;
    }
}
