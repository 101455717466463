<mat-card
    [class.border-box-top-accent]="color == 'accent'"
    [class.border-box-top-primary]="color == 'primary'"
    [class.border-box-top-warn]="color == 'warn'"
>
    <mat-card-header>
        <mat-card-title>
            {{ titulo }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-card-content-text">
        <p class="card-text">
            <span>
                {{ descricao }}
            </span>
        </p>
    </mat-card-content>
</mat-card>
