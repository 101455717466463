import { ProjetoRowCardService } from './projeto-row-card.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sbcnv-projeto-row-card',
    templateUrl: './projeto-row-card.component.html',
    styleUrls: ['./projeto-row-card.component.scss'],
})
export class ProjetoRowCardComponent {
    get statusDescricao(): string {
        if (
            this.contrato === '0' &&
            (this.status === '40' || this.status === '41')
        ) {
            return 'Aguardando assinatura';
        }
        return this.statusLista.find((status) => status.codigo === this.status)
            ?.descricao as string;
    }
    get icone(): string {
        return this.statusLista.find((status) => status.codigo === this.status)
            ?.icone as string;
    }
    @Input() public listaNome = '';
    @Input() public codigo = '';
    @Input() public parceiro = '';
    @Input() public convenio = '';
    @Input() public status = '';
    @Input() public percentualSebrae = '';
    @Input() public contrato = '';
    @Input() public tipoProjeto = '';
    @Output() eventoClick = new EventEmitter();

    constructor(private _projetoRowCardService: ProjetoRowCardService) { }

    private statusLista = [
        { codigo: '0', descricao: 'Em digitação', icone: 'restore' },
        { codigo: '10', descricao: 'Solicitado', icone: 'access_time' },
        { codigo: '20', descricao: 'Em análise', icone: 'access_time' },
        { codigo: '30', descricao: 'Analisado', icone: 'access_time' },
        {
            codigo: '40',
            descricao: 'Aprovado em andamento',
            icone: 'access_time',
        },
        { codigo: '41', descricao: 'Pendente', icone: 'access_time' },
        { codigo: '50', descricao: 'Reprovado', icone: 'access_time' },
        { codigo: '60', descricao: 'Encerrado', icone: 'access_time' },
        { codigo: '61', descricao: 'Cancelado', icone: 'access_time' },
    ];

    chamarEventoClick(evento: FocusEvent): void {
        this._projetoRowCardService.setarProjetoSelecionado(
            this.listaNome,
            this.codigo
        );
        this.eventoClick.emit(evento);
    }

    verificarSelecao(): boolean {
        const projetoSelecionado = this._projetoRowCardService.pegarProjetoSelecionado(
            this.listaNome
        );
        if (typeof projetoSelecionado === 'string') {
            return projetoSelecionado === this.codigo;
        } else {
            return false;
        }
    }
}
