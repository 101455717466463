export class Responsavel {
    constructor(
        public cod_crc: string,
        public cpf: string,
        public email: string,
        public ind_vinculo: string,
        public nome: string,
        public num_pessoa_jurid: string,
        public observacao: string,
        public telefone: string,
        public responsavel: Responsavel[]
    ) {}
}
