<div [class]="class">
    <mat-form-field appearance="outline" [color]="color">
        <mat-label>{{ label }}</mat-label>
        <textarea
            matInput
            [placeholder]="placeholder"
            [formControlName]="controlName"
            [readonly]="readonly"
            [maxlength]="maxlength"
        ></textarea>
        <mat-hint *ngIf="maxlength !== ''">
            {{tamanho}}/{{maxlength}}
        </mat-hint>
        <mat-error>
            <sbcnv-validacao-formulario
                [formulario]="formulario"
                [controlName]="controlName"
                [label]="label"
            >
            </sbcnv-validacao-formulario>
        </mat-error>
    </mat-form-field>
    <sbcnv-hint
        *ngIf="hintConteudo"
        [titulo]="hintTitulo"
        [conteudo]="hintConteudo"
    >
    </sbcnv-hint>
</div>
