import { AutenticacaoService } from '@app/service/autenticacao.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sbcnv-info-usuario',
    templateUrl: './info-usuario.component.html',
    styleUrls: ['./info-usuario.component.scss'],
})
export class InfoUsuarioComponent implements OnInit {
    @Input() nome = '';
    @Input() urlImagem = '';
    usuarioTipo: number | undefined;

    constructor(private _autenticacaoService: AutenticacaoService) {}

    ngOnInit(): void {
        this.usuarioTipo = this._autenticacaoService.pegarUsuarioTipo();
    }

    public get UrlImagem(): string {
        return `url('${this.urlImagem})'`;
    }

    efetuarLogout(): void {
        this._autenticacaoService.logout();
    }
}
