import { DocumentoTipo } from './../../models/projetos/documento-tipo';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take} from 'rxjs/operators';
import { HttpHelper } from '../../../core/helpers/http-helper';
import { DocumentosProjeto } from '../../models/projetos/documento-projeto';
import { IRetorno } from '../../models/suporte/iretorno';
import { ArrayHelper } from '@app/helpers/array-helper';
import {UtilHelper} from "@app/helpers/util-helper";

@Injectable({
    providedIn: 'root',
})
export class DocumentoProjetoService {
    private readonly url = '/api/projeto/arquivo';
    private readonly url_adiantamento = '/api/projeto/adiantamento';
    constructor(private http: HttpClient) { }

    public carregarDocumentosProjeto(
        idProjeto: string, filtro: string
    ): Observable<DocumentosProjeto> {
        const params = {
            cdn_projeto: idProjeto,
            seq_anexo: '0',
            cdn_tipo_docto : filtro
        };
        return this.http
            .get<DocumentosProjeto>(
                `${this.url}/${HttpHelper.pegarDataFormatoIso()}`,
                {
                    params,
                }
            ).pipe(catchError((error) => {
                return throwError({
                    body: params,
                    error: error,
                });
            }));
    }

    private montarBodyParametrosDocumento(
        idProjeto: string,
        tipoDocumento: string,
        arquivo: File
    ): FormData {
        const fd = new FormData();
        fd.append('cdn_projeto', idProjeto);
        fd.append('seq_anexo', '0');
        fd.append('cdn_tipo_docto', tipoDocumento);
        fd.append('file', arquivo, arquivo.name.replace(/ +/g, '_'));
        return fd;
    }

    public incluirDocumento(
        idProjeto: string,
        tipoDocumento: string,
        arquivo: File

    ): Observable<IRetorno> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'multipart/form-data'
            ),
        };

        const fd = this.montarBodyParametrosDocumento(
            idProjeto, tipoDocumento, arquivo
        );

        fd.append('acao', 'incluir_arquivo');

        return this.http
            .post<{ retorno: IRetorno }>(
                this.url,
                fd,
                options
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: UtilHelper.formDataToString(fd),
                        error: 'Não foi possível incluir o documento.',
                    });
                })
            );
    }

    public deletarDocumento(
        idProjeto: string,
        idAnexo: string,
    ): Observable<IRetorno> {
        const params = {
            cdn_projeto: idProjeto,
            seq_anexo: idAnexo
        };

        return this.http
            .delete<{ retorno: IRetorno }>(this.url, {params})
            .pipe(
                map((res) => res.retorno ?? res),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: params,
                        error: 'Não foi possível excluir o documento.',
                    });
                })
            );
    }

    pegarTiposDocumento(filtro?: string): Observable<DocumentoTipo[]> {
        let urlDoc = `${this.url}-tipo/`;

        if (filtro && filtro.length > 0){
            urlDoc += filtro;
        }

        return this.http
            .get<{ documento: DocumentoTipo | DocumentoTipo[] }>(
                urlDoc
            )
            .pipe(
                // timeout(2500),
                take(1),
                map((res) => {
                    if (res === null) {
                        return [];
                    }
                    if (Array.isArray(res.documento)) {
                        return ArrayHelper.normalizar<DocumentoTipo>(res.documento);
                    } else if (res.documento !== undefined) {
                        return [
                            new DocumentoTipo(
                                res.documento.cdn_tipo_docto,
                                res.documento.desc_tipo_docto
                            ),
                        ];
                    } else {
                        return [];
                    }
                }),
                catchError((error) => {
                    return throwError({
                        body: urlDoc.toString(),
                        error: 'Não foi possível carregar os itens do campo tipo de documento.',
                    });
                })
            );
    }

    public enviarAdiantamento(
        projetoId: string,
        cpfRepresentante: string,
        valorRecibo: string
        ): Observable<IRetorno> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'application/x-www-form-urlencoded'
            ),
        };

        const body = new URLSearchParams();
        body.set('cdn_projeto', projetoId);
        body.set('cpf', cpfRepresentante);
        body.set('val_recibo', valorRecibo);

        return this.http
            .post<{ retorno: IRetorno }>(
                `/api/projeto/arquivo/enviar-adiantamento?${body}`,
                {}
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: JSON.stringify(body.toString()),
                        error: error,
                    });
                })
            );
    }

    public consultarAdiantamento(idProjeto: string): Observable<any> {
        const params = { cdn_projeto: idProjeto };
        return this.http.get<any>(
            `${this.url_adiantamento}/${HttpHelper.pegarDataFormatoIso()}`, { params }
        );
    }

    public validarAdiantamento(
        projeto_id: string,
        cpf: string,
        ind_aprova_adiantamento: string,
        dsc_obs_revisao: string,
        cod_usuario: string,
        ): Observable<any> {
        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                'application/x-www-form-urlencoded'
            ),
        };

        const body = new URLSearchParams();
        body.set('cdn_projeto', projeto_id);
        body.set('cpf', cpf);
        body.set('ind_aprova_adiantamento', ind_aprova_adiantamento);
        body.set('dsc_obs_revisao', dsc_obs_revisao);
        body.set('cod_usuario', cod_usuario);

        return this.http
            .post<{ retorno: IRetorno }>(
                `/api/projeto/adiantamento?${body}`,
                {}
            )
            .pipe(
                map((res) => res.retorno),
                take(1),
                catchError((error) => {
                    return throwError({
                        body: JSON.stringify(body.toString()),
                        error: error,
                    });
                })
            );
    }

}
