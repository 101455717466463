<div [class]="class">
    <mat-form-field appearance="outline" [color]="color">
        <mat-label>{{ label }}</mat-label>
        <mat-select [formControlName]="controlName" [placeholder]="placeholder" (ngModelChange)="chamarEventoChange($event)">
            <mat-option
                *ngFor="let opcao of opcoes; opcaoErro"
                [value]="opcao.valor"
            >
                {{ opcao.texto }}</mat-option
            >
        </mat-select>
        <ng-template #opcaoErro>
            <mat-select>
                <mat-option value=""
                    >Não foi possível carregar a lista</mat-option
                >
            </mat-select>
        </ng-template>
        <mat-error>
            <sbcnv-validacao-formulario
                [formulario]="formulario"
                [controlName]="controlName"
                [label]="label"
            >
            </sbcnv-validacao-formulario>
        </mat-error>
    </mat-form-field>
    <sbcnv-hint
        *ngIf="hintConteudo"
        [titulo]="hintTitulo"
        [conteudo]="hintConteudo"
    >
    </sbcnv-hint>
</div>
