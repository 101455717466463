<div>
    <mat-sidenav-container hasBackdrop="false">
        <mat-sidenav mode="push" [disableClose]="true" opened fixedInViewport>
            <img
                src="../../../assets/images/logo.png"
                class="logo ml-20 mt-28 mb-28"
            />
            <mat-divider></mat-divider>
            <sbcnv-menu [menu]="menu"></sbcnv-menu>

            <footer *ngIf="configuracaoService.config.versao && configuracaoService.config.versao != ''">
                {{ 'Versão: ' + configuracaoService.config.versao }}
            </footer>
        </mat-sidenav>
        <mat-sidenav-content class="pb-12" style="height: calc(100vh - 12px)">
            <sbcnv-cabecalho>
                <sbcnv-info-usuario
                    [nome]="pegarNomeUsuarioLogado()"
                    urlImagem="/assets/images/avatar-sample.jpg"
                >
                </sbcnv-info-usuario>
            </sbcnv-cabecalho>

            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<ngx-spinner type="timer"></ngx-spinner>
