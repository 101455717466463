import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formataConvenio',
})
export class FormataConvenioPipe implements PipeTransform {
    transform(value: string): string {
        let valorFormatado = '-';
        if (value !== undefined) {
            if (value.length === 8) {
                valorFormatado = `${value.substring(
                    0,
                    4
                )}/${value.substring(4)}`;
            }
        }
        return valorFormatado;
    }
}
