<div [class]="class">
    <mat-form-field appearance="outline" [color]="color">
        <mat-label>{{ label }}</mat-label>
        <input matInput [placeholder]="placeholder" [formControlName]="controlName" [matDatepicker]="picker" (blur)="chamarEventoBlur($event)" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
            <sbcnv-validacao-formulario [formulario]="formulario" [controlName]="controlName" [label]="label">
            </sbcnv-validacao-formulario>
        </mat-error>
    </mat-form-field>
    <sbcnv-hint *ngIf="hintConteudo" [titulo]="hintTitulo" [conteudo]="hintConteudo">
    </sbcnv-hint>
</div>
