import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Evento } from 'src/app/data/models/suporte/event/evento';
import { TipoEvento } from 'src/app/data/models/suporte/event/tipo-evento.enum';

@Injectable({
    providedIn: 'root'
})
export class EventoService {
    private _subject$: Subject<Evento> = new Subject();

    public emit(evento: Evento): void {
        this._subject$.next(evento);
    }

    public on(tipoEvento: TipoEvento, action: (value: object) => void): Subscription {
        return this._subject$.pipe(
            filter((e: Evento) => e.tipoEvento === tipoEvento),
            map((e: Evento) => e.value)
        ).subscribe(action);
    }
}
