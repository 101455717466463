<mat-card>
    <div class="mt-20 ml-20 mr-20 mb-40">
        <h3
            *ngIf="idAditivoDescricao === 0 || idAditivoDescricao === undefined"
            style="opacity: 60%; color: black; margin-top: 1em"
        >
            Conferir ações e despesas inclusas - Projeto {{ projetoId }}
        </h3>
        <h3
            *ngIf="idAditivoDescricao !== 0 && idAditivoDescricao !== undefined"
            style="opacity: 60%; color: black; margin-top: 1em"
        >
            Conferir ações e despesas inclusas - Projeto {{ projetoId }} -
            {{ idAditivoDescricao }}
        </h3>
        <br />
        <!-- inicio tabela -->
        <div>
            <div *ngFor="let acao of acoes; index as indexAcao">
                <span class="span-acao">Ação: {{ acao.dsc_acao }}</span>
                <!-- inicio tablela -->

                <table
                    *ngIf="pegarDespesas(acao).length > 0"
                    mat-table
                    [dataSource]="pegarDespesas(acao)"
                    class="mat-elevation-z8"
                >
                    <!-- Coluna id -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Id</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{ element.seq_numerica }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
                    </ng-container>

                    <ng-container matColumnDef="desc_despesa">
                        <th mat-header-cell *matHeaderCellDef>Despesa</th>
                        <td mat-cell *matCellDef="let element" class="despesa">
                            <span
                                matTooltip="{{
                                    !verificarPropriedadeNaoNulaOuVazia(
                                        element,
                                        'desc_despesa'
                                    )
                                        ? element.dsc_despesa
                                        : element.desc_despesa
                                }}"
                            >
                                {{
                                    !verificarPropriedadeNaoNulaOuVazia(
                                        element,
                                        "desc_despesa"
                                    )
                                        ? element.dsc_despesa
                                        : element.desc_despesa
                                }}
                            </span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- Coluna Valor Parceiro -->
                    <ng-container matColumnDef="valorParceiro">
                        <th mat-header-cell *matHeaderCellDef>
                            Valor parceiro
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                !verificarPropriedadeNaoNulaOuVazia(
                                    element,
                                    "vlr_desp_parceiro"
                                )
                                    ? element.vl_desp_parceiro
                                    : element.vlr_desp_parceiro
                            }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <b>{{
                                pegarAcaoValorParceiro(pegarDespesas(acao))
                                    | currency: "" : ""
                            }}</b>
                        </td>
                    </ng-container>

                    <!-- Coluna Valor Sebrae -->
                    <ng-container matColumnDef="valorSebrae">
                        <th mat-header-cell *matHeaderCellDef>Valor sebrae</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                !verificarPropriedadeNaoNulaOuVazia(
                                    element,
                                    "vlr_desp_sebrae"
                                )
                                    ? element.vl_desp_sebrae
                                    : element.vlr_desp_sebrae
                            }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <b>{{
                                pegarAcaoValorSebrae(pegarDespesas(acao))
                                    | currency: "" : ""
                            }}</b>
                        </td>
                    </ng-container>

                    <!-- Coluna Valor Total -->
                    <ng-container matColumnDef="valorTotal">
                        <th mat-header-cell *matHeaderCellDef>Valor total</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                !verificarPropriedadeNaoNulaOuVazia(
                                    element,
                                    "vlr_total"
                                )
                                    ? (converterStringParaFloat(
                                          element?.vl_desp_parceiro
                                      ) +
                                          converterStringParaFloat(
                                              element.vl_desp_sebrae
                                          ) | currency: "" : "")
                                    : element.vlr_total
                            }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <b>{{
                                pegarAcaoValorTotal(pegarDespesas(acao))
                                    | currency: "" : ""
                            }}</b>
                        </td>
                    </ng-container>

                    <!-- Coluna destinada aos botões -->
                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="acoes">
                            <button
                                class="icon-button"
                                mat-icon-button
                                aria-label="visualizar despesa"
                                matTooltip="visualizar despesa"
                                (click)="carregarDialogDespesa(element, acao)"
                            >
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="exibirColunasDespesa"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: exibirColunasDespesa"
                    ></tr>
                    <tr
                        mat-footer-row
                        *matFooterRowDef="exibirColunasDespesa"
                    ></tr>
                </table>
                <br />
                <mat-divider></mat-divider>
                <br />

                <!-- fim tabela -->
            </div>

            <fieldset>
                <legend>Soma Ações</legend>
                <div class="flex-container-30">
                    <div class="flex-item-30">
                        <fieldset class="input">
                            <legend>Parceiro</legend>
                            <div>
                                <p>{{ projeto.vlr_total_parceiro }}</p>
                            </div>
                        </fieldset>
                    </div>
                    <div class="flex-item-30">
                        <fieldset class="input">
                            <legend>Sebrae</legend>
                            <div>
                                <p>{{ projeto.vlr_total_sebrae }}</p>
                            </div>
                        </fieldset>
                    </div>
                    <div class="flex-item-30">
                        <fieldset class="input">
                            <legend>Projeto</legend>
                            <div>
                                <p>{{ projeto.vlr_total_projeto }}</p>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </fieldset>
        </div>
        <!-- fim tabela -->
    </div>
</mat-card>
