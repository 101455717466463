import { Injectable } from '@angular/core';

import { Stepper } from './stepper';

@Injectable({
    providedIn: 'root',
})
export class StepperService {
    private stepper: Map<string, Stepper> = new Map();

    constructor() { }

    public configurarStep(
        nome: string,
        stepper: object,
        idItem: string = '0',
        aditivoId: string = '0',
        ehNovo = false
    ): void {
        if (this.Stepper(nome) === undefined) {
            if (ehNovo) {
                this.stepper.delete(nome);
            }
            this.stepper.set(nome, stepper as Stepper);
        }
        this.setarIdItem(nome, idItem, aditivoId);
    }

    public Stepper(nome: string): Stepper {
        return this.stepper.get(nome) as Stepper;
    }

    private setarIdItem(nome: string, idItem: string, aditivoId: string): void {
        this.Stepper(nome).idItem = idItem;
        this.Stepper(nome).aditivoId = aditivoId;
    }

    public marcarPasso(
        nome: string,
        passo: number,
        concluido: StepConcluido = StepConcluido.vazio,
        estado?: StepEstado
    ): void {
        this.Stepper(nome).steps[passo].concluido = concluido;
    }
}

export enum StepConcluido {
    'vazio' = '',
    'sim' = 'true',
    'nao' = 'false',
}
export enum StepEstado {
    'vazio' = '',
    'feito' = 'done',
    'editando' = 'edit',
}
