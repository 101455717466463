import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AutenticacaoService } from '../service/autenticacao.service';

@Injectable()
export class AutenticacaoInterceptor implements HttpInterceptor {
    constructor(private authService: AutenticacaoService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (this.ignoreURL(request.url)) {
            return next.handle(request);
        } else {
            const authToken = this.authService.getAuthorizationToken() as string;

            return next.handle(
                request.clone({
                    setHeaders: { TokenAuthorization: authToken },
                })
            );
        }
    }

    private ignoreURL(url: string): boolean {
        const ignorarURLs = [
            './assets/config/app.config.json',
            '/convenios/api/token',
            '/convenios/api/cep',
        ];
        return ignorarURLs.some((e) => url.includes(e));
    }
}
