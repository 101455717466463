import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sbcnv-cabecalho',
    templateUrl: './cabecalho.component.html',
    styleUrls: ['./cabecalho.component.scss'],
})
export class CabecalhoComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
