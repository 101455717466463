import { Component, Input } from '@angular/core';

@Component({
    selector: 'sbcnv-titulo-card',
    templateUrl: './titulo-card.component.html',
    styleUrls: ['./titulo-card.component.scss'],
})
export class TituloCardComponent {
    @Input() public color = '';
    @Input() public titulo = '';
    @Input() public descricao = '';

    constructor() {}
}
