<mat-card
    [class.border-box-top-accent]="color == 'accent'"
    [class.border-box-top-primary]="color == 'primary'"
    [class.border-box-top-warn]="color == 'warn'"
>
    <mat-card-content class="mat-card-content-text">
        <p class="card-text">
            <span>
                {{ titulo }}
            </span>
        </p>
    </mat-card-content>
</mat-card>
