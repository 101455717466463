export class ObjectHelper {
    public static recreate<T>(
        typeT: new () => T,
        sourceObj: T,
        targetObj?: T
    ): T {
        if (targetObj == null) {
            targetObj = new typeT();
        }

        for (const key in sourceObj) {
            if (Object.prototype.hasOwnProperty.call(sourceObj, key)) {
                targetObj[key] = sourceObj[key];
            }
        }

        return targetObj;
    }
}
