import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import {
    STEPPER_GLOBAL_OPTIONS,
    StepperSelectionEvent,
} from '@angular/cdk/stepper';
import { StepperService } from './stepper.service';
import { Step } from './step';

@Component({
    selector: 'sbcnv-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})
export class StepperComponent {
    @Input() public passoAtual = 0;
    @Input() public ehLinear = true;
    @Input() public nome = '';

    public selectedStepIndex = 0;
    public loadingStep = false;

    constructor(
        private router: Router,
        private _stepperService: StepperService
    ) { }

    get Steps(): Array<Step> {
        return this._stepperService.Stepper(this.nome).steps;
    }

    selectionChanged(event: StepperSelectionEvent): void {
        this.selectedStepIndex = event.selectedIndex;
        this.navigate();
    }

    private navigate(): void {
        let idEdicao = '';
        let aditivoId = '';
        if (this._stepperService.Stepper(this.nome).idItem !== '0') {
            idEdicao = `/${this._stepperService.Stepper(this.nome).idItem}`;
        }
        if (this._stepperService.Stepper(this.nome).aditivoId !== '0') {
            aditivoId = `/${this._stepperService.Stepper(this.nome).aditivoId}`;
        }
        const url = `${this._stepperService.Stepper(this.nome).steps[
                this.selectedStepIndex
            ].url
            }${idEdicao}${aditivoId}`;
        this.router.navigate([url], { skipLocationChange: true });
    }
}
