import {Component, OnInit} from '@angular/core';

import { Menu } from '@data/models/suporte/menu';
import { ItemMenu } from '@data/models/suporte/item-menu';
import { AutenticacaoService } from '@app/service/autenticacao.service';
import { Router } from '@angular/router';
import { TokenService } from '@app/service/token.service';
import {ConfiguracaoService} from "@app/service";
import { Login } from '@data/models/autenticacao/login';

@Component({
    selector: 'sbcnv-conteudo-layout',
    templateUrl: './conteudo-layout.component.html',
    styleUrls: ['./conteudo-layout.component.scss'],
})
export class ConteudoLayoutComponent implements OnInit {
    public menu: Menu = {
        nomeAplicacao: 'CONVÊNIOS',
        itens: [] as Array<ItemMenu>,
    };

    public isUgc = false;
    public usuario: Login | null;

    constructor(
        private _autenticacaoService: AutenticacaoService,
        private router: Router,
        private _tokenService: TokenService,
        public configuracaoService: ConfiguracaoService,
    ) {
        this.usuario = this._autenticacaoService.usuario;
        this.isUgc = this.usuario?.grp_usuario == 'UGC';
    }

    ngOnInit(): void {
        let usuarioTipo = 'nao_autenticado';
        if (this._autenticacaoService.usuario) {
            usuarioTipo = this._autenticacaoService.usuario.tipo;
        }

        if (usuarioTipo === 'sebrae') {
            this.menu.itens.push({
                descricao: 'Elaboração e avaliação',
                link: '/projetos/elaboracao/lista-sebrae',
                icon: 'history',
                skipLocationChange: true,
            });
        }

        if (usuarioTipo === 'Parceiro') {
            this.menu.itens.push({
                descricao: 'Elaboração e avaliação',
                link: '/projetos/elaboracao/lista-parceiro',
                icon: 'history',
                skipLocationChange: true,
            });
        }

        if (usuarioTipo === 'nao_autenticado' || usuarioTipo === 'sebrae') {
            this.menu.itens.push({
                descricao: 'Projetos em análise',
                link: '/projetos/analise/lista',
                icon: 'fact_check',
                skipLocationChange: true,
            });
        }

        this.menu.itens.push({
            descricao: 'Projetos em execução',
            link: '/projetos/execucao/lista',
            icon: 'grade',
            skipLocationChange: true,
        });

        this.menu.itens.push({
            descricao: 'Finalizados',
            link: '/projetos/finalizados/lista',
            icon: 'archive',
            skipLocationChange: true,
        });

        if (usuarioTipo === 'sebrae' && this.isUgc) {
            this.menu.itens.push({
                descricao: 'Relatório - Auditoria',
                link: '/projetos/relatorios/auditoria',
                icon: 'summarize',
                skipLocationChange: true,
            });

            this.menu.itens.push({
                descricao: 'Relatório - Visão Geral',
                link: '/projetos/relatorios/visao-geral',
                icon: 'plagiarism',
                skipLocationChange: true,
            });
        }

        if (this.router.url === '/') {
            this._tokenService.removerToken();
            this._autenticacaoService.logout();
        }
    }


    pegarNomeUsuarioLogado(): string {
        let nomeUsuario = '';
        if (this._autenticacaoService.usuario) {
            nomeUsuario = this._autenticacaoService.usuario.nome;
        }
        return nomeUsuario;
    }
}
