import { Component, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sbcnv-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
    @Input() titulo = '';
    @Input() conteudo = '';
    @Input() icone = 'info';
    @Input() btnCancelar = 'sim';
    @Input() btnConfirmarTexto = 'CONFIRMAR';
    @Input() btnCancelarTexto = 'CANCELAR';

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            titulo: string;
            conteudo: string;
            icone: string;
            btnCancelar: string;
            btnConfirmarTexto: string;
            btnCancelarTexto: string;
        }
    ) {
        if (data.icone !== undefined) {
            this.icone = data.icone;
        } else {
            this.icone = 'info';
        }

        if (data.btnCancelar !== undefined) {
            this.btnCancelar = data.btnCancelar;
        }
        if (data.btnConfirmarTexto !== undefined) {
            this.btnConfirmarTexto = data.btnConfirmarTexto;
        }
        if (data.btnCancelarTexto !== undefined) {
            this.btnCancelarTexto = data.btnCancelarTexto;
        }
    }

    public cancelar(): void {
        this.dialogRef.close(false);
    }

    public confirmar(): void {
        this.dialogRef.close(true);
    }
}
