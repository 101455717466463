import { Component } from '@angular/core';

@Component({
    selector: 'sbcnv-barra-acao',
    templateUrl: './barra-acao.component.html',
    styleUrls: ['./barra-acao.component.scss'],
})
export class BarraAcaoComponent {
    constructor() {}
}
