import { Component, OnInit } from '@angular/core';
import { Projeto } from '@data/models/projetos/projeto';
import { Repasse } from '@data/models/projetos/repasse';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacaoService } from '@app/service';
import { NumberHelper } from '@app/helpers/number-helper';
import { ObjectHelper } from '@app/helpers/object-helper';
import { AnaliseTecnica } from '@data/models/projetos/analise-tecnica';
import { UtilHelper } from '@app/helpers/util-helper';

@Component({
    selector: 'sbcnv-resumo-repasses',
    templateUrl: './resumo-repasses.component.html',
    styleUrls: ['./resumo-repasses.component.scss'],
})
export class ResumoRepassesComponent implements OnInit {
    public projetoId = '0';
    public aditivoId = '0';
    public idAditivoDescricao = 0;

    public voltarUrl = '';

    public repasses: Repasse[] = [];
    dataSourceRepasse = new MatTableDataSource(this.repasses);

    saldoRecursoSebrae = 'R$ 0,00';
    saldoRecursoParceiro = 'R$ 0,00';
    valorPositivoSebrae = true;
    valorPositivoParceiro = true;
    totalDespesasSebrae = '';
    totalDespesasParceiro = '';

    public projeto!: Projeto;

    exibirColunasRepasse: string[] = ['id', 'parcela', 'tipoRepasse', 'valor'];

    repasseTipos = [
        { valor: '', texto: 'Selecione o tipo de repasse' },
        { valor: '1', texto: 'SEBRAE MG - Parceiro' },
        { valor: '2', texto: 'Parceiro - SEBRAE MG' },
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _autenticacaoService: AutenticacaoService,
    ) {}

    ngOnInit(): void {
        this.idAditivoDescricao = 0;

        // Pega do resolve os dados do projeto
        if (this.route.snapshot.data.projeto) {
            this.projeto = this.route.snapshot.data.projeto;

            if (this.route.snapshot.data.repasses) {
                this.repasses = this.route.snapshot.data.repasses.repasse;
            } else {
                this.repasses = this.preencherRepasses();
            }

            this.totalDespesasParceiro = this.projeto.vlr_total_parceiro;
            this.totalDespesasSebrae = this.projeto.vlr_total_finan_sbr;

            this.calcularSaldoRecurso(
                NumberHelper.converterValorRealParaFloat(
                    this.totalDespesasSebrae,
                ),
                NumberHelper.converterValorRealParaFloat(
                    this.totalDespesasParceiro,
                ),
            );
            this.dataSourceRepasse.data = this.repasses;

            this.projeto.analise_tec = ObjectHelper.recreate(
                AnaliseTecnica,
                this.projeto.analise_tec,
            );
            this.projetoId = this.projeto.cdn_projeto;

            this.voltarUrl = UtilHelper.replaceCuringaTipoUsuarioUrlVoltar(
                true,
                this.route.snapshot.data.voltarUrl,
                this._autenticacaoService.usuario,
            );
        }

        // Pegar parametros da rota
        this.route.params.subscribe((parametros) => {
            if (parametros.id) {
                this.projetoId = parametros.id;
            }
            if (parametros.seqAditivo) {
                this.aditivoId = parametros.seqAditivo;
            }
        });

        if (this.aditivoId !== '0') {
            this.idAditivoDescricao = Number(this.aditivoId) - 1;
        } else {
            this.idAditivoDescricao = Number(this.projeto.ind_aditivo_cnv);
        }
    }

    preencherRepasses() {
        if (Array.isArray(this.projeto.repasses.repasse)) {
            return this.projeto.repasses.repasse.reduce(
                (acc: any, cur: any) => {
                    acc.push({
                        cdn_projeto: cur.cdn_projeto,
                        dat_real_repasse: cur.dat_real_repasse,
                        dsc_tipo_repasse: cur.dsc_tipo_repasse,
                        parcela: cur.cod_parc_rec,
                        rowid: cur.rowid,
                        tipoRepasse: cur.ind_tipo_repasse,
                        valor: cur.vlr_repasse_format,
                        vlr_repasse: cur.vlr_repasse,
                    });
                    return acc;
                },
                [],
            );
        } else {
            return [this.projeto.repasses.repasse].reduce(
                (acc: any, cur: any) => {
                    acc.push({
                        cdn_projeto: cur.cdn_projeto,
                        dat_real_repasse: cur.dat_real_repasse,
                        dsc_tipo_repasse: cur.dsc_tipo_repasse,
                        parcela: cur.cod_parc_rec,
                        rowid: cur.rowid,
                        tipoRepasse: cur.ind_tipo_repasse,
                        valor: cur.vlr_repasse_format,
                        vlr_repasse: cur.vlr_repasse,
                    });
                    return acc;
                },
                [],
            );
        }
    }

    pegarDescricaoTipoRepassePorId(id: string): string {
        return this.repasseTipos.find((tipo) => tipo.valor === id)
            ?.texto as string;
    }

    voltar(): void {
        this.router.navigate([this.voltarUrl], {
            skipLocationChange: true,
        });
    }

    calcularSaldoRecurso(recursoSebrae: number, recursoParceiro: number): void {
        let totalSebrae = 0;
        let totalParceiro = 0;
        this.repasses.forEach((repasse) => {
            if (repasse.tipoRepasse === '1') {
                totalSebrae = NumberHelper.somarValoresNoFormatoReal(
                    String(totalSebrae),
                    repasse.valor,
                );
            } else if (repasse.tipoRepasse === '2') {
                totalParceiro = NumberHelper.somarValoresNoFormatoReal(
                    String(totalParceiro),
                    repasse.valor,
                );
            }
        });
        this.saldoRecursoSebrae = NumberHelper.converterFloatParaValorReal(
            NumberHelper.somarValoresNoFormatoReal(
                String(recursoSebrae),
                String(-totalSebrae),
            ),
        );

        this.valorPositivoSebrae = recursoSebrae >= totalSebrae;

        this.saldoRecursoParceiro = NumberHelper.converterFloatParaValorReal(
            NumberHelper.somarValoresNoFormatoReal(
                String(recursoParceiro),
                String(-totalParceiro),
            ),
        );

        this.valorPositivoParceiro = recursoParceiro >= totalParceiro;
    }
}
