export class NumberHelper {
    public static pegarSomenteNumero(valor: string): number {
        return Number(valor.replace(/\D/gim, ''));
    }

    public static removerPontos(valor: string): string {
        if (
            valor.length - valor.indexOf('.') !== 3 &&
            valor.length - valor.indexOf('.') !== 2
        ) {
            valor = String(valor).replace(/\./gi, '');
        }
        return valor;
    }

    public static converterValorRealParaFloat(valor: string): number {
        return Number(this.removerPontos(String(valor)).replace(',', '.'));
    }

    public static converterFloatParaValorReal(valor: number): string {
        let novoValor = this.removerPontos(valor.toString());
        novoValor = novoValor.toString().replace('.', ',');
        if (novoValor.indexOf(',') === -1) {
            return novoValor + ',00';
        } else if (novoValor.length - novoValor.indexOf(',') === 2) {
            return novoValor + '0';
        }
        return novoValor;
    }

    public static somarValoresNoFormatoReal(
        valor1: string,
        valor2: string,
        somar: boolean = true
    ): number {
        if (somar) {
            return Number(
                (
                    this.converterValorRealParaFloat(valor1) +
                    this.converterValorRealParaFloat(valor2)
                ).toFixed(2)
            );
        } else {
            return Number(
                (
                    this.converterValorRealParaFloat(valor1) -
                    this.converterValorRealParaFloat(valor2)
                ).toFixed(2)
            );
        }
    }
}
