export class ArrayHelper {
    public static normalizar<T>(obj: T[] | T[][]): T[] {
        let resultado: T[] = [];

        if (obj) {
            if (Array.isArray(obj)) {
                obj.forEach((element: T | T[]) => {
                    if (element) {
                        if (Array.isArray(element)) {
                            resultado = resultado.concat(element);
                        } else {
                            resultado.push(element);
                        }
                    }
                });
            } else {
                resultado.push(obj);
            }
        }

        return resultado;
    }
}
