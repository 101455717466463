import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProjetoRowCardService {
    private projetoRowCard: Map<string, string> = new Map();

    constructor() {}

    public configurar(nome: string, id: string): void {
        this.projetoRowCard.set(nome, id);
    }

    public limparSelecao(nome: string): void {
        this.projetoRowCard.delete(nome);
    }

    public setarProjetoSelecionado(nome: string, id: string): void {
        this.projetoRowCard.set(nome, id);
    }

    public pegarProjetoSelecionado(nome: string): string | undefined {
        return this.projetoRowCard.get(nome);
    }
}
