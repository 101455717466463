<div [class]="class">
    <mat-form-field appearance="outline" [color]="color">
        <mat-label>{{ label }}</mat-label>
        <input
            *ngIf="evento === ''"
            matInput
            [type]="type"
            [min]="min"
            [max]="max"
            [mask]="ngxMask"
            [validation]="ngxValidation"
            [thousandSeparator]="ngxThousandSeparator"
            [placeholder]="placeholder"
            [formControlName]="controlName"
        />
        <input
            *ngIf="evento === 'blur'"
            matInput
            [type]="type"
            [min]="min"
            [max]="max"
            [mask]="ngxMask"
            [validation]="ngxValidation"
            [thousandSeparator]="ngxThousandSeparator"
            [placeholder]="placeholder"
            [formControlName]="controlName"
            (blur)="chamarEventoBlur($event)"
        />
        <input *ngIf="evento === 'input'" matInput [type]="type" [min]="min" [max]="max" [mask]="ngxMask"
            [validation]="ngxValidation" [thousandSeparator]="ngxThousandSeparator" [placeholder]="placeholder"
            [formControlName]="controlName" (input)="chamarEventoInput($event)" />
        <span
            class="corInterna"
            style="font-size: 14px; float: right"
            *ngIf="prefix !== ''"
            matPrefix
            >{{ prefix }}</span
        >
        <span
            class="corInterna"
            style="font-size: 14px; float: right"
            *ngIf="suffix !== ''"
            matSuffix
            >{{ suffix }}</span
        >
        <mat-error
            ><sbcnv-validacao-formulario
                [formulario]="formulario"
                [controlName]="controlName"
                [label]="label"
            >
            </sbcnv-validacao-formulario>
        </mat-error>
    </mat-form-field>
    <sbcnv-hint
        *ngIf="hintConteudo"
        [titulo]="hintTitulo"
        [conteudo]="hintConteudo"
    >
    </sbcnv-hint>
</div>
