import { Injectable } from '@angular/core';

import { DialogComponent } from './dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private dialog: MatDialog) {}

    public exibir(data: {}): void {
        this.dialog.open(DialogComponent, { data });
    }
}
