<div class="container">
    <div class="conteudo">
        <h1 class="titulo" *ngIf="data.titulo" mat-dialog-title>
            {{ data.titulo }}
        </h1>
        <div mat-dialog-content>
            <span [innerHTML]="data.conteudo"></span>
        </div>

        <div mat-dialog-actions align="center">
            <button color="accent" mat-raised-button (click)="cancelar()">
                {{ btnCancelar }}
            </button>
            <button color="accent" mat-raised-button (click)="confirmar()">
                {{ btnConfirmar }}
            </button>
        </div>
    </div>
</div>
