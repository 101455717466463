import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    ControlContainer,
    FormGroupDirective,
    FormGroup,
    AbstractControl,
} from '@angular/forms';
import { Helpers } from '../helpers';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'sbcnv-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class DatepickerComponent {
    @Input() public class = '';
    @Input() public placeholder = '';
    @Input() public label = '';
    @Input() public controlName = '';
    @Input() public color: ThemePalette = 'accent';
    @Input() public hintTitulo = '';
    @Input() public hintConteudo = '';
    @Input() public formulario!: FormGroup;
    @Output() eventoBlur = new EventEmitter();

    events: string[] = [];

    constructor() {}

    chamarEventoBlur(evento: FocusEvent): void {
        this.eventoBlur.emit(evento);
    }

    get campo(): AbstractControl {
        return Helpers.getFormControl(this.controlName, this.formulario);
    }
}
