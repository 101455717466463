<ng-container>
    <mat-toolbar class="bg-white">
        <h1>Portal de Convênios</h1>
        <span class="spacer"></span>
        <ng-content select="sbcnv-info-usuario"></ng-content>
    </mat-toolbar>
    <mat-toolbar class="toolbar">
        <sbcnv-breadcrumbs></sbcnv-breadcrumbs>
    </mat-toolbar>
</ng-container>
