<mat-card (click)="chamarEventoClick($event)" [class.projeto-selecionado]="verificarSelecao()">
    <div class="projeto-icone">
        <div *ngIf="tipoProjeto === '2'">
            <b>
                S
            </b>
        </div>
        <div *ngIf="tipoProjeto !== '2'">
            <b>
                P
            </b>
        </div>
    </div>
    <div style="margin-left: 110px; margin-right: 45px">
        <table>
            <tr>
                <th align="left" width="120px">Código Projeto</th>
                <th align="left" class="colunaParceiro">Parceiro</th>
                <th align="left" width="120px">Convênio</th>
                <th align="left" width="120px">Status</th>
                <th align="center" width="120px">%Sebrae</th>
            </tr>
            <tr>
                <td width="120px">{{ codigo }}</td>
                <td class="colunaParceiro">{{ parceiro }}</td>
                <td width="120px">{{ convenio | formataConvenio }}</td>
                <td width="120px">{{ statusDescricao }}</td>
                <td width="120px" align="center">{{ percentualSebrae }}%</td>
            </tr>
        </table>
    </div>
    <div class="projeto-menu">
        <ng-content></ng-content>
    </div>
</mat-card>
