import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const req = request.headers.has('Content-type')
            ? request
            : request.clone({
                  setHeaders: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                  },
              });

        return next.handle(req);
    }
}
