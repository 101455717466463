import { Component, Input } from '@angular/core';

import {
    FormGroup,
    AbstractControl,
    ControlContainer,
    FormGroupDirective,
} from '@angular/forms';
import { Helpers } from '../helpers';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'sbcnv-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class TextareaComponent {
    @Input() public class = '';
    @Input() public placeholder = '';
    @Input() public label = '';
    @Input() public controlName = '';
    @Input() public color: ThemePalette = 'accent';
    @Input() public hintTitulo = '';
    @Input() public hintConteudo = '';
    @Input() public maxlength = '';
    @Input() public formulario!: FormGroup;
    @Input() public readonly = false;

    constructor() {}

    get tamanho(): number {
        return String(this.campo.value).length;
    }

    get campo(): AbstractControl {
        return Helpers.getFormControl(this.controlName, this.formulario);
    }
}
