import { Component, OnInit } from '@angular/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Projeto } from '@data/models/projetos/projeto';
import { Acao } from '@data/models/projetos/acao';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AutenticacaoService } from '@app/service';
import { NumberHelper } from '@app/helpers/number-helper';
import { UtilHelper } from '@app/helpers/util-helper';
import { ArrayHelper } from '@app/helpers/array-helper';
import { Despesa } from '@data/models/projetos/despesa';
import { DialogDespesaComponent } from '@modules/projetos/projeto/shared/dialog-despesa/dialog-despesa.component';

@Component({
    selector: 'sbcnv-resumo-acoes-despesas',
    templateUrl: './resumo-acoes-despesas.component.html',
    styleUrls: ['./resumo-acoes-despesas.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
            ),
        ]),
    ],
})
export class ResumoAcoesDespesasComponent implements OnInit {
    public projetoId = '0';
    public aditivoId = '0';
    public idAditivoDescricao = 0;

    public projeto!: Projeto;

    public voltarUrl = '';

    public acoes: Acao[] = [];

    exibirColunasDespesa: string[] = [
        'id',
        'desc_despesa',
        'valorParceiro',
        'valorSebrae',
        'valorTotal',
        'acoes',
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _dialog: MatDialog,
        private _autenticacaoService: AutenticacaoService,
    ) {}

    ngOnInit(): void {
        this.idAditivoDescricao = 0;

        // Pega do resolve os dados do projeto
        if (this.route.snapshot.data.projeto) {
            this.projeto = this.route.snapshot.data.projeto;

            if (this.route.snapshot.data.acoes) {
                this.acoes = this.route.snapshot.data.acoes;
            } else {
                this.acoes = this.preencherAcoes();
            }

            this.projetoId = this.projeto.cdn_projeto;

            this.voltarUrl = UtilHelper.replaceCuringaTipoUsuarioUrlVoltar(
                true,
                this.route.snapshot.data.voltarUrl,
                this._autenticacaoService.usuario,
            );
        }

        // Pegar parametros da rota
        this.route.params.subscribe((parametros) => {
            if (parametros.id) {
                this.projetoId = parametros.id;
            }
            if (parametros.seqAditivo) {
                this.aditivoId = parametros.seqAditivo;
            }
        });

        if (this.aditivoId !== '0') {
            this.idAditivoDescricao = Number(this.aditivoId) - 1;
        } else {
            this.idAditivoDescricao = Number(this.projeto.ind_aditivo_cnv);
        }
    }

    preencherAcoes() {
        if (Array.isArray(this.projeto.etapas.etapa.acoes.acao)) {
            this.projeto.etapas.etapa.acoes.acao.forEach(
                (e: any, index: any) => {
                    e.despesas =
                        this.projeto.etapas.etapa.acoes.despesas[index].despesa;
                },
            );
            return this.projeto.etapas.etapa.acoes.acao;
        } else {
            this.projeto.etapas.etapa.acoes.acao.despesas = [
                this.projeto.etapas.etapa.acoes.despesas.despesa,
            ];
            return [this.projeto.etapas.etapa.acoes.acao];
        }
    }

    pegarDespesas(acao: Acao): Array<Despesa> {
        return ArrayHelper.normalizar<Despesa>(acao.despesas);
    }

    pegarAcaoValorTotal(despesas: Array<Despesa>): number {
        return despesas
            .map((despesa: any) =>
                this.verificarPropriedadeNaoNulaOuVazia(despesa, 'vlr_total')
                    ? NumberHelper.converterValorRealParaFloat(
                          despesa.vlr_total,
                      )
                    : this.converterStringParaFloat(despesa.vl_desp_parceiro) +
                      this.converterStringParaFloat(despesa.vl_desp_sebrae),
            )
            .reduce((acc, value) => acc + value, 0);
    }

    converterStringParaFloat(value: any) {
        return NumberHelper.converterValorRealParaFloat(value);
    }

    verificarPropriedadeNaoNulaOuVazia(obj: any, propriedade: string): boolean {
        if (propriedade in obj) {
            const valor = obj[propriedade];
            return valor !== null && valor !== '';
        }
        return false;
    }

    pegarAcaoValorParceiro(despesas: Array<Despesa>): number {
        return despesas
            .map((despesa) =>
                NumberHelper.converterValorRealParaFloat(
                    despesa.vlr_desp_parceiro
                        ? despesa.vlr_desp_parceiro
                        : despesa.vl_desp_parceiro ?? '0',
                ),
            )
            .reduce((acc, value) => acc + value, 0);
    }

    pegarAcaoValorSebrae(despesas: Array<Despesa>): number {
        return despesas
            .map((despesa) =>
                NumberHelper.converterValorRealParaFloat(
                    despesa.vlr_desp_sebrae
                        ? despesa.vlr_desp_sebrae
                        : despesa.vl_desp_sebrae ?? '0',
                ),
            )
            .reduce((acc, value) => acc + value, 0);
    }

    voltar(): void {
        this.router.navigate([this.voltarUrl], {
            skipLocationChange: true,
        });
    }

    carregarDialogDespesa(despesa: Despesa, acao: Acao): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '80vw';
        dialogConfig.minHeight = '25vw';
        dialogConfig.maxHeight = '40vw';
        dialogConfig.data = {
            despesa,
            acao,
            idProjeto: this.projetoId,
        };

        const dialogRef = this._dialog.open(
            DialogDespesaComponent,
            dialogConfig,
        );
    }
}
