import { Component, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sbcnv-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    @Input() titulo = '';
    @Input() conteudo = '';
    @Input() icone = 'info';
    @Input() isErroServidor = false;
    showErro = false;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            titulo: string;
            conteudo: any;
            icone: string;
            isErroServidor: boolean;
            jsonChamada: string;
            jsonRetorno: string;
            lista: any;
        }
    ) {
        if (data.icone !== undefined) {
            this.icone = data.icone;
        } else {
            this.icone = 'info';
        }

        if (data.isErroServidor) {
            this.isErroServidor = data.isErroServidor;
        }
    }

    public fechar(): void {
        this.dialogRef.close();
    }

    public verErro(): void {
        this.showErro = !this.showErro;
    }

    public quebrarLinha(data: string) {
        return data.replace(/\n/g, '<br>');
    }
}
