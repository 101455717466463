import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { UrlInterceptor } from './url.interceptor';
import { HeadersInterceptor } from './headers.interceptor';
import { AutenticacaoInterceptor} from './autenticacao.interceptor';
import { ErrorInterceptor} from './error.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: UrlInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: HeadersInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AutenticacaoInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: ErrorInterceptor }
];
