<mat-card>
    <div class="mt-20 ml-20 mr-20 mb-40">
        <h3 *ngIf="idAditivoDescricao === 0 || idAditivoDescricao === undefined"
            style="opacity: 60%; color: black; margin-top: 1em;">
            Conferir dados - Projeto {{ projetoId }}
        </h3>
        <h3 *ngIf="idAditivoDescricao !== 0 && idAditivoDescricao !== undefined"
            style="opacity: 60%; color: black; margin-top: 1em;">
            Conferir dados - Projeto {{ projetoId }} - {{ idAditivoDescricao }}
        </h3>
        <br>
        <!-- inicio formulario -->
        <div>

            <fieldset class="input">
                <legend>Nome do projeto</legend>
                <div>
                    <p>{{ projeto.dsc_titulo }}</p>
                </div>
            </fieldset>
            <fieldset class="input" style="max-width: 300px">
                <legend>% Participação Sebrae</legend>
                <div>
                    <p>{{  projeto.dec_percent_particip_sebrae }}% </p>
                </div>
            </fieldset>
            <fieldset>
                <legend>Justificativa</legend>
                <div class="textarea">
                    <p>{{ projeto.dsc_justificativa }}</p>
                </div>
            </fieldset>
            <fieldset>
                <legend>Público-alvo</legend>
                <div class="textarea">
                    <p>{{ projeto.dsc_publico_alvo }}</p>
                </div>
            </fieldset>
            <div style="width: 100%; display: flex;">
                <fieldset class="input" style="width: 50%; max-width: 350px; float: left;">
                    <legend>Acompanhante Parceiro</legend>
                    <div>
                        <p>{{ pegarAcompanhanteParceiro() }}</p>
                    </div>
                </fieldset>
                <fieldset class="input" style=" max-width: 100px; float: left;margin-left: 1em;">
                    <legend>Prazo (meses)</legend>
                    <div>
                        <p>{{ projeto.prazo }}</p>
                    </div>
                </fieldset>
            </div>

            <fieldset class="input">
                <legend>Acompanhante Sebrae</legend>
                <div>
                    <p>{{ pegarAcompanhanteSebrae() }}</p>
                </div>
            </fieldset>

            <fieldset class="input">
                <legend>Responsável Legal Parceiro</legend>
                <div>
                    <p>{{ pegarResponsavelLegal() }}</p>
                </div>
            </fieldset>

            <fieldset>
                <legend>Objetivo geral</legend>
                <div class="textarea">
                    <p>{{ projeto.dsc_objetivo_geral }}</p>
                </div>
            </fieldset>

            <fieldset>
                <legend>
                    Resultados finalísticos
                </legend>
                <div class="textarea">
                    <p>{{ projeto.dsc_resultados_finalisticos }} </p>
                </div>
            </fieldset>

            <fieldset>
                <legend>
                    Avaliação dos resultados
                </legend>
                <div class="textarea">
                    <p>
                        {{
                        projeto.dsc_avalia_resultados
                        }}
                    </p>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    Destinação e propriedade
                </legend>
                <div class="textarea">
                    <p>
                        {{ projeto.dsc_destinacao }}
                    </p>
                </div>
            </fieldset>
        </div>
        <!-- fim formulario -->
        <div *ngIf="dataSourceDocumentosProjeto.length > 0">
            <!-- inicio tabela -->
            <h3 style="opacity: 60%; color: black; margin-top: 1em;">
                Documentos anexados
            </h3>
            <table style="margin-top: 2em" id="tablePrestacaoDocumentos" mat-table
                   [dataSource]="dataSourceDocumentosProjeto">
                <ng-container matColumnDef="desc_anexo">
                    <th mat-header-cell *matHeaderCellDef>Tipo</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.desc_tipo_docto }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nome_arq_proj">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let element" (click)="abrirArquivoURL(element.seq_anexo)">
                        {{ element.nome_arq_proj }}
                    </td>
                </ng-container>

                <!-- Coluna destinada aos botões -->
                <ng-container matColumnDef="acoes">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="visualizar anexo"
                                matTooltip="visualizar anexo" (click)="abrirArquivoURL(element.seq_anexo)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="exibirColunasDocumentoProjeto"></tr>
                <tr mat-row *matRowDef="let row; columns: exibirColunasDocumentoProjeto"></tr>
            </table>
            <!-- fim tabela -->
        </div>
    </div>
</mat-card>
