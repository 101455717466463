export class AnaliseTecnica {
    public dsc_historico = '';
    public dsc_objetivo = '';
    public dsc_metas = '';
    public dsc_indicadores = '';
    public dsc_relevancia = '';
    public dsc_validacao = '';
    public dsc_parecer = '';
    public local_arq_analise = '';
    public nome_arq_analise = '';
    public file?: File;
}
