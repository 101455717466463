import { Injectable, ErrorHandler } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AutenticacaoService } from '@app/service/autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AutenticacaoService,
        private _snackBar: MatSnackBar,
        private spinner: NgxSpinnerService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                let errorMessage: string;

                if (error.error instanceof ErrorEvent) {
                    // Erro no lado cliente
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    if (error.status === 401) {
                        this.authService.logout();
                    }

                    // Retorno do backend
                    errorMessage = `Error Status: ${error.status}\nMessage: ${
                        error.message
                    }\nURL: ${error.url}\nBody: ${JSON.stringify(error.error)}`;
                }

                this.spinner.hide();
                if (!error.message.includes('convenios/api/token')) {
                    // todo snackbar com erro
                    /* this._snackBar.open(errorMessage, undefined, {
                        duration: 5000,
                        panelClass: ['snackbar-warn'],
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });*/
                }

                return throwError(errorMessage);
            })
        );
    }
}
