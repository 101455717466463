<div style="padding-left: 12px; position: absolute !important">
    <span *ngFor="let menu of menu.itens" [class]="['cursor-pointer', 'm-8']">
        <button
            mat-button
            [routerLink]="[menu.link]"
            [skipLocationChange]="menu.skipLocationChange"
            routerLinkActive="ativo"
        >
            <mat-icon [class]="['mr-4']">{{ menu.icon }}</mat-icon>
            {{ menu.descricao }}
        </button>
    </span>
</div>
