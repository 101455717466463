import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '@shared/shared.module';
import {
    CoreModule,
    httpInterceptorProviders,
    ConfiguracaoService,
} from '@app/index';

import {
    ConteudoLayoutComponent,
    CabecalhoComponent,
    MenuComponent,
} from './layout';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
registerLocaleData(pt);

export function initicializarConfiguracao(
    configuracaoService: ConfiguracaoService
): () => void {
    return () => configuracaoService.carregarConfiguracao();
}

@NgModule({
    declarations: [
        AppComponent,
        ConteudoLayoutComponent,
        CabecalhoComponent,
        MenuComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule,
        CoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,

        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initicializarConfiguracao,
            deps: [ConfiguracaoService],
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        { provide: LOCALE_ID, useValue: 'pt' },
        httpInterceptorProviders,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
