import { AutenticacaoService } from './../../core/service/autenticacao.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import { DialogService } from '@shared/dialog/dialog.service';
import {throwError} from "rxjs";

@Component({
    selector: 'sbcnv-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
    public breadcrumbs: Array<IBreadcrumb> = [];
    @Input() public deliminator = '';
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private _autenticacaoService: AutenticacaoService,
        private _dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                catchError((error) => {
                    return throwError({
                        body: null,
                        error: error,
                    });
                })
            )
            .subscribe(
                (event) => {
                    this.breadcrumbs = [];
                    let currentRoute = null;
                    currentRoute = this.route.root;
                    let url = '';
                    do {
                        const childrenRoutes = currentRoute.children;
                        currentRoute = null;
                        childrenRoutes.forEach((route) => {
                            if (route.outlet === 'primary') {
                                const routeSnapshot = route.snapshot;
                                url += `'/'${routeSnapshot.url
                                    .map((segment) => segment.path)
                                    .join('/')}`;
                                this.breadcrumbs.push({
                                    label: route.snapshot.data.titulo,
                                    url,
                                    voltar: route.snapshot.data.voltar,
                                    voltarUrl:
                                        this.replaceCuringaTipoUsuarioUrlVoltar(
                                            route.snapshot.data.voltar,
                                            route.snapshot.data.voltarUrl
                                        ),
                                });
                                currentRoute = route;
                            }
                        });
                    } while (currentRoute);
                },
                (erro) => {
                    this._dialogService.exibir({
                        titulo: 'Atenção',
                        conteudo: erro.error,
                        jsonChamada: JSON.stringify(erro.body),
                        isErroServidor: true,
                    });
                }
            );
    }

    private replaceCuringaTipoUsuarioUrlVoltar(
        voltar: boolean,
        voltarUrl: string
    ): string {
        let url = voltarUrl;
        if (this._autenticacaoService.usuario && voltar) {
            url = String(voltarUrl).replace(
                '#tipousuario#',
                this._autenticacaoService.usuario.tipo.toLowerCase()
            );
        }
        return url;
    }
}

export interface IBreadcrumb {
    label: string;
    url: string;
    voltar: boolean;
    voltarUrl: string;
}
