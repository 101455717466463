<div class="container">
    <div *ngIf="icone !== 'sem-icone'" class="icone" [class.icone-conteudo]="!data.titulo">
        <mat-icon inline="true">{{ icone }}</mat-icon>
    </div>
    <div class="conteudo">
        <h1 *ngIf="data.titulo" mat-dialog-title>{{ data.titulo }}</h1>
        <div mat-dialog-content>
            {{ data.conteudo }}
        </div>
        <div mat-dialog-actions align="end">
            <button
                *ngIf="btnCancelar === 'sim'"
                color="accent"
                mat-button
                (click)="cancelar()"
            >
                {{ btnCancelarTexto }}
            </button>
            <button color="accent" mat-raised-button (click)="confirmar()">
                {{ btnConfirmarTexto }}
            </button>
        </div>
    </div>
</div>
