import { FormGroup, AbstractControl } from '@angular/forms';

export class Helpers {
    static getFormControl(
        nomeControle: string,
        formulario: FormGroup
    ): AbstractControl {
        if (!nomeControle) {
            throw new Error('Nome do controle não pode ser nulo');
        }

        const formControl = formulario.get(nomeControle);

        if (formControl === null) {
            throw new Error(`Controle ${nomeControle} não localizado`);
        }

        return formControl;
    }
}
