import { Component, OnInit, Input } from '@angular/core';

import { Menu } from '@data/models/suporte/menu';

@Component({
    selector: 'sbcnv-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    @Input() public menu: Menu = new Menu();

    constructor() {}

    ngOnInit(): void {}
}
