import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConteudoLayoutComponent } from './layout/';

import { AutenticacaoGuard } from '@app/index';

const routes: Routes = [
    {
        path: '',
        component: ConteudoLayoutComponent,
        canActivate: [AutenticacaoGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'projetos',
                loadChildren: () =>
                    import('@modules/projetos/projetos.module').then(
                        (module) => module.ProjetosModule
                    ),
            },
            {
                path: 'usuario',
                loadChildren: () =>
                    import('@modules/usuario/usuario.module').then(
                        (module) => module.UsuarioModule
                    ),
            },
        ],
    },
    {
        path: 'autenticacao',
        loadChildren: () =>
            import('@modules/autenticacao/autenticacao.module').then(
                (module) => module.AutenticacaoModule
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
