<ng-container>
    <button id="btnUsuario" class="mat-small" mat-button [matMenuTriggerFor]="menu">{{ nome }}<mat-icon>arrow_drop_down</mat-icon></button>

    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/usuario/trocar-senha" [skipLocationChange]="true" *ngIf="usuarioTipo == 1">
            <mat-icon>vpn_key</mat-icon>
            <span>Alterar senha</span>
        </button>
        <button mat-menu-item (click)="efetuarLogout()">
            <mat-icon>logout</mat-icon>
            <span>Efetuar logout</span>
        </button>
    </mat-menu>
</ng-container>
