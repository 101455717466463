import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AutenticacaoService } from '../service/autenticacao.service';

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AutenticacaoService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const usuario = this.authenticationService.usuario;

        if (usuario) {
            return true;
        }

        this.router.navigate(['/autenticacao/login'], {
            skipLocationChange: true,
        });

        return false;
    }
}
