import { Component, OnInit } from '@angular/core';
import { Projeto } from '@data/models/projetos/projeto';
import { Participe } from '@data/models/projetos/participe';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacaoService } from '@app/service';
import { UtilHelper } from '@app/helpers/util-helper';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'sbcnv-resumo-participes',
    templateUrl: './resumo-participes.component.html',
    styleUrls: ['./resumo-participes.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
            ),
        ]),
    ],
})
export class ResumoParticipesComponent implements OnInit {
    public projetoId = '0';
    public aditivoId = '0';
    public idAditivoDescricao = 0;

    public projeto!: Projeto;

    public voltarUrl = '';

    public elementoExpandido!: Participe | null;

    public participes: Array<Participe> = [];

    dataSourceParticipe = new MatTableDataSource(this.participes);

    exibirColunasParticipe: string[] = ['id', 'desc_fornecedores', 'acoes'];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _autenticacaoService: AutenticacaoService,
    ) {}

    ngOnInit(): void {
        this.idAditivoDescricao = 0;

        // Pega do resolve os dados do projeto
        if (this.route.snapshot.data.projeto) {
            this.projeto = this.route.snapshot.data.projeto;

            this.participes =
                this.route.snapshot.data.projeto.participes.participe;
            if (this.participes) {
                if (Array.isArray(this.participes)) {
                    this.participes = this.participes;
                    this.dataSourceParticipe.data = this.participes;
                } else {
                    const arrayParticipes: Participe[] = [];
                    arrayParticipes.push(this.participes);
                    this.dataSourceParticipe.data = arrayParticipes;
                }
            }

            this.projetoId = this.projeto.cdn_projeto;

            this.voltarUrl = UtilHelper.replaceCuringaTipoUsuarioUrlVoltar(
                true,
                this.route.snapshot.data.voltarUrl,
                this._autenticacaoService.usuario,
            );
        }

        // Pegar parametros da rota
        this.route.params.subscribe((parametros) => {
            if (parametros.id) {
                this.projetoId = parametros.id;
            }
            if (parametros.seqAditivo) {
                this.aditivoId = parametros.seqAditivo;
            }
        });

        if (this.aditivoId !== '0') {
            this.idAditivoDescricao = Number(this.aditivoId) - 1;
        } else {
            this.idAditivoDescricao = Number(this.projeto.ind_aditivo_cnv);
        }
    }

    voltar(): void {
        this.router.navigate([this.voltarUrl], {
            skipLocationChange: true,
        });
    }
}
