import { HttpParams } from '@angular/common/http';

export class HttpHelper {
    // tslint:disable-next-line: no-any
    public static transformarParaHttpParams(data: any): HttpParams {
        let httpParams = new HttpParams();

        Object.keys(data).forEach((key: string) => {
            httpParams = httpParams.set(key, data[key]);
        });

        return httpParams;
    }

    public static pegarDataFormatoIso(): string {
        return new Date().toISOString().replace(/-/g, '');
    }
}
